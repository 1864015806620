/**********************************Boen Project Variables***************************/

/**********************************Search Button************************************/
$beon-primary-btn-color: #FFEB00 ;
$beon-primary-btn-hover-focus-color: #EBD300;
$beon-primary-btn-active-color: #806F00;
$beon-primary-btn-disabled-color:#E0E0E0 ;
$beon-primary-btn-width: 100%;
/**********************************Search Button************************************/
/************************Secondary Button variables*********/
$beon-clear-btn-color: #FFFFFF;
$beon-clear-btn-hover-color: #E0E0E0;
$beon-clear-btnborder-hover-color:#C6C6C6;
$beon-clear-btn-width: 100%;

/************************secondary Button variables*********/
/******************Heading Variables******************/

$heading-padding-top: 24px;
$heading-padding-bottom: 12px;
$heading-padding-left: 1%;
$heading-font-size: 18px;
$h5-margin-top: 10px;

/******************Heading Variables******************/

/******************Nav Variables******************/

$nav-font-size: 15px;
$nav-logo-margin-left: 20px;
$nav-img-width: 40%;
$nav-img-height: 25%;

/******************Nav Variables******************/

/******************Discovery Label Variables******************/

$label-color: #000000;
$label-font-size: 14px;

/******************Discovery Label Variables******************/

/******************Background color for components******************/

$white-color: #ffffff;
$background-color: #fafafa;

/******************Background color for components******************/

/******************search pannal and Invite Button Padding**********/

$search-panal-padding-top: 10px;
$search-panal-padding-bottom: 10px;
$invite-button-padding: 10px;
$invite-button-height: 58%;

/******************search pannal and Invite Button Paddings*********/

/******************Button and Button Hover Colors*******************/

$blurrywood: #deb887;
$indigo: #00004d;
$search-bg-color: #fcfbfa;
$search-hover-bg-color: #ffe4c4;
$red: #ff0000;

/******************Button and Button Hover Colors******************/

/*************************Static Pages Controls*************************/

$static-pad-top: 10px;
$static-sec-font-size: 20px;
$static-text-align: center;
$static-font-size: 30px;
$norecord-font-size: 18px;

/************************* Spinner&toggle Controls*************************/

$toggle-height: 1.4rem;
$toggle-width: 4rem;
$spinner-font-size: 21px;

/*************************Spinner&toggle Controls*************************/

/*************************Primeng Controls*************************/

$input-height: 30px;
$font-size: 10px;
$p-textbox-color: #000000;
$p-dropdown-width: 100%;
$p-textbox-width: 100%;

/*************************Primeng Controls*************************/

/*************************datatable Controls*************************/

$discovery-datatable-th-pad: 0.7rem;
$discovery-datatable-td-pad: 0.5rem;

/*************************datatable Controls*************************/

/************************Discovery-Search Button variables*********/
$discovery-search-btn-color: #ffc52b;
$discovery-search-btn-hover-color: #ffe4c4;
$discovery-search-btn-width: 100%;

/************************Discovery-Search Button variables*********/

/************************Discovery-Clear Button variables*********/
$discovery-clear-btn-color: #ff0000;
$discovery-clear-btn-hover-color: #ffffff;
$discovery-clear-btn-width: 100%;
$discovery-clear-btn-height: 97%;
/************************Discovery-Clear Button variables*********/

/************************Discovery-Submit-Cancel Button variables*********/

$submit-btn-width: 100%;
$cancel-btn-width: 100%;

/************************Discovery-Submit-Cancel Button variables*********/

/************************P-Dialog variables*********/

$p-dialog-header-height: 50px;
$p-dialog-header-bg-color: #fff2cf;
$p-caurosal-header-color: #fade92;

/************************P-Dialog variables*********/

/************************Discovery-Table variables*********/
$table-bg-color: #6b6865;
$table-color: #ffffff;
$strip-bg-color: #e6e6e6;

/************************Discovery-Table variables*********/

/*************************Application Controls*************************/

$applicatiton-bg-color: #f4f4f4;
$application-mrgn-top: 3.6%;
$application-mrgn-bottom: 5%;
$toast-bottom: 30px;

/*************************Application Controls*************************/

/*************************Accordion Colors*************************/

$accordion-bg-color: #fff2cf;
$p-accordian-pad-btm: 5px;

/*************************Accordion Colors*************************/

/*************************Scrollbar variables*************************/

$scrollbar-width: 100%;
$scrollbar-height: 41.6em;
$scrollbar-bg-color: #fff2cf;
$scrollbar-thumb-bg-color: #ffc52b;
$scrollbar-thumb-width: 10px;
$scrollbar-brd-radius: 6px;

/*************************Scrollbar variables*************************/

/*************************File Upload variables*************************/

$upload-choose-color: #fbc02d;
$upload-choose-color-hover: #ffe4c4;
$upload-buttonbar-color: #f8f9fa;

/*************************File Upload variables*************************/

/*************************p-card variables*************************/

$card-height: 100%;
$card-title-color: #221f1f;
$card-subtitle-color: #182853;
$card-content-color: #a0a0a0;
$card-footer-color: #236092;
$card-hover-color: #fdf1d0;
$card-icon-right: 1.5rem;
$card-content-height1:100px;
$card-content-height2:200px;

/*************************p-card variables*************************/

/*************************p-calendar variables*************************/

$calendar-color:#495057;

/*************************p-calendar variables*************************/

/*************************Data Component variables*************************/

$step-info-font-size: 13px;
$step-info-font-color: #0a0a0a;
$step-title-font-size: 14px;
$step-title-font-weight: bold;
$step-title-color: #221f1f;
$content-step-file-type-color: #e6362d;
$step-gray-box-width: 100%;
$step-gray-box-margin-top: 5px;
$step-gray-box-padding: 10px;
$step-gray-box-font-size: 15px;
$step-gray-box-color: #236092;
$step-gray-box-bg-color: #fafafa;
$content-padding: 30px;
$content-bg-color: #ffffff;
$content-step-img-width: 18px;
$content-step-img-height: 18px;
$progress-bar-li-width: 25%;
$progress-bar-li-height: 30px;
$progress-bar-li-bg-color: #ffffff;
$progress-bar-li-after-bg-color: #a0a0a0;
$progress-bar-li-after-content: "";
$progress-bar-li-after-width: 100%;
$progress-bar-li-after-height: 1px;
$progress-bar-li-after-top: 25px;
$progress-bar-li-after-left: -50%;
$progress-bar-li-after-zindex: -1;

/*************************Data Component variables*************************/

$link-color: #2f6695;
$link-color-hover: #00152b;
$primary-color: #333;
$font-stack: Verdana, sans-serif;
$fa-edit-hover: #ffc52b;
$p-multiselect-width: 100%;
$black: #000000;
$enquiry-input-bg-color: #f4f4f4;
$global-font-size: 14px;
$global-font-size-no: 14;
$Progressbar-color:#4caf50;

/********************Data Component Variables******************************/
$home-color: #fade92;
$home-zoom: 236%;
$home-button: #182853;

/*************************Data Component Variables*************************/

/*****************PToolTip & Overlay*************************************************/

$ToolTip-bgcolor: #182853;
$ToolTip-color:#ffffff;
$ToolTip-padTop:0px;
$ToolTip-padBot:0px;

$speeddail-right:3em;
$speeddail-bottom:4em;
$speeddail-button-color:#000000;

$Overlay-width:365px;
$Overlay-height:200px;

/*****************PToolTip & Overlay*************************************************/

/*******************************Dashboard Variables*************************************************/

$button-rounded-margin-left:-12px;
$button-rounded-margin-top:-2px;
$button-rounded-height:1.5rem;
$button-icon-width:1.5rem;

$splitter-gutter-width:2px;
$splitter-gutter-bg:#000000;

/*******************************Dashboard Variables*************************************************/

/*******************************PickList Variables*************************************************/

$picklist-button-color:#000000;
$picklist-filter-container-padding:0.5rem;

/*******************************PickList Variables*************************************************/

/*******************************Mandatory Variables*************************************************/

$mandatory-content:"*";
$mandatory-font-size:20px;
$mandatory-color:#ff0000;

/*******************************Mandatory Variables*************************************************/

/*******************************FileUpload Variables*************************************************/

$upload-content-border:2px dashed #008cff;
$upload-content-width:100%;
$upload-content-height:100px;
$upload-content-MarTop:2px;
$upload-content-MarBtm:5px;
$upload-content-bg:#eef7ff;


$buttonbar-padbtm:1rem;
$buttonbar-padleft:0.2rem;
$buttonbar-padtop:0.5rem;
$buttonbar-padright:0rem;
$buttonbar-bg:#ffffff;


/*******************************FileUpload Variables*************************************************/


