/* You can add global styles to this file, and also import other style files */
@import "./assets/sass/_variables.scss";
@import "./assets/sass/spinner.scss";


@function getPxValue($number) {
  @return $number;
}

@function getVwValue($number) {
  @return (100 * $number / 1500);
}

/************************Global Styles of Beon ******************************/

/**********************Styles for Heading tags*******************************/
h1 {
  font-size: 32px;
  line-height: 38px;
  font-family: GraphikMedium;
  color: #161616;
}

h2 {
  font-size: 24px;
  line-height: 28px;
  font-family: GraphikMedium;
  color: #161616;
}

h3 {
  font-size: 20px;
  line-height: 24px;
  font-family: GraphikMedium;
  color: #161616;
}

h4 {
  font-size: 16px;
  line-height: 20px;
  font-family: GraphikMedium;
  color: #161616;
}

h5 {
  font-size: 14px;
  line-height: 16px;
  font-family: GraphikMedium;
  color: #161616;
}

h6 {
  font-size: 14px;
  line-height: 16px;
  font-family: GraphikMedium;
  color: #161616;
}

label.L1 {
  font-size: 16px;
  line-height: 20px;
  font-family: GraphikRegular;
  color: #161616;
}

label.L2 {
  font-size: 14px;
  line-height: 16px;
  font-family: GraphikRegular;
  color: #161616;
}

label.L3 {
  font-size: 12px;
  line-height: 14px;
  font-family: GraphikRegular;
  color: #161616;
}

p {
  font-size: 14px;
  line-height: 20px;
  font-family: GraphikRegular;
  color: #161616;
}

/**********************Styles for Heading tags*******************************/

/************************Primary Button Styles******************************/
.p-button .p-button-label{
   display: grid !important;
   align-self: flex-start !important;

}
.beon-primary-btn {
  .p-button-label {
    font-family: GraphikMedium;
    color: #161616 !important;
    font-size: 14px !important;
    
  }

  .p-primary-btn {
    width: 100% !important;
    background-color: #FFEB00 !important;
    border-radius: 100px !important;
    border-color: none !important;
    height: 40px !important;

  }

  .p-primary20-btn {
    color: #161616 !important;
    width: 25% !important;
    background-color: #FFEB00 !important;
    border-radius: 100px !important;
    border-color: none !important;
    height: 40px !important;

  }

  .p-primary-btn:enabled {
    border: none !important;
  }

  .p-primary-btn:hover {
    background-color: #EBD300 !important;
  }

  .p-primary-btn:focus {
    background-color: #EBD300 !important;
    border: 1px solid #007A8A !important;
    box-shadow: 0 0 0 0 transparent !important;
  }

  .p-primary-btn:active {
    background-color: #B8A200 !important;
    border: none !important;
    box-shadow: 0 0 0 0 transparent !important;
  }

  .p-primary-btn:disabled {
    background-color: #E0E0E0 !important;
    border: none !important;

    .p-button-label {
      color: #8D8D8D !important;
    }
  }
}

.p-primary20-btn {
  .p-button-label {
    color: #161616 !important;
    font-size: 14px !important;
    font-family: GraphikMedium;
  }

  color: #161616 !important;
  width:25% !important;
  background-color:#FFEB00 !important;
  border: 1px solid #FFEB00 !important;
  box-shadow: 0 0 0 0 transparent !important;
  border-radius: 100px !important;
  border-color: none !important;
  height: 40px !important;
}

.p-primary20-btn:hover {
  background-color: #EBD300 !important;
}

/************************Primary Button Styles******************************/

/************************Secondary Button Styles******************************/
.beon-secondary-btn {
  .p-button-label {
    font-family: GraphikMedium;
    font-weight: 500 !important;
    color: #161616 !important;
    font-size: 14px !important;

  }

  .p-secondary-button {
    width: $beon-clear-btn-width;
    background-color: $beon-clear-btn-color !important;
    border: 1px solid #8D8D8D !important;
    border-radius: 100px;
    height: 40px !important;
  }

  .p-secondary-button:hover:not([disabled]) {
    background-color: #F4F4F4 !important;

  }

  .p-secondary-button:focus {
    background-color: #F4F4F4 !important;
    border: 1px solid #007A8A !important;
    box-shadow: 0 0 0 0 transparent !important;

  }

  .p-secondary-button:active:not([disabled]) {
    background-color: #E0E0E0 !important;
    border: 1px solid #8D8D8D !important;

  }

  .p-secondary-button:disabled {
    background-color: #FFFFFF !important;
    border: 1px solid #e0e0e0 !important;

    .p-button-label {
      color: #8d8d8d !important;
      font-size: 16px !important;
    }
  }
}

/************************Secondary Button Styles of Beon ******************************/

/************************Teritary and icon Text Button Styles of Beon ******************************/
.beon-teritary-btn {
  .p-button-label {
    font-family: GraphikMedium;
    font-size: $global-font-size;
    color: #009AAB !important;

  }

  .p-teritary-btn {
    width: $beon-clear-btn-width;
    height: 40px !important;
    color: #005D6B !important;
    background-color: transparent !important;
    border: 1px transparent !important;
    border-radius: 3rem;
    box-shadow: none;
  }

  .p-teritary-btn:hover:not([disabled]) {
    width: $beon-clear-btn-width;
    background-color: #E3FDFF !important;
    border: 1px solid #E3FDFF !important;
  }

  .p-teritary-btn:focus {
    width: $beon-clear-btn-width;
    background-color: #E3FDFF !important;
    box-shadow: 0px 0px 1px 1px #009AAB !important;
  }

  .p-teritary-btn:active:not([disabled]) {
    width: $beon-clear-btn-width;
    background-color: #00F0FF !important;
    box-shadow: none !important;
  }

  .p-teritary-btn:disabled {
    width: $beon-clear-btn-width;
    box-shadow: none !important;

    .p-button-label {
      font-size: $global-font-size;
      color: grey !important;
      font-size: bold !important;
    }

    .pi-plus:before {
      color: grey !important
    }
  }

}

/************************Teritary and icon Text Button  Styles of Beon ******************************/

/************************Split Button Styles of Beon ******************************/
.beon-split-btn {
  .p-splitbutton-defaultbutton {
    background-color: #FFEB00 !important;
    border: none !important;
    border-bottom-left-radius: 1rem !important;
    border-top-left-radius: 1rem !important;
    border-right: 1px solid #222222 !important;

  }

  .p-splitbutton-menubutton {
    background-color: #FFEB00 !important;
    border: none !important;
    border-bottom-right-radius: 1rem !important;
    border-top-right-radius: 1rem !important;

  }

  .p-button-label {
    font-family: GraphikMedium;
    font-size: $global-font-size;
    color: black !important;
    font-size: bold !important;
  }

  .p-button-icon {
    color: black !important;
  }

  .p-splitbutton-defaultbutton:hover {
    .p-splitbutton-menubutton {
      background-color: #EBD300 !important;
    }
  }

  .p-splitbutton-menubutton:hover:not([disabled]) {
    background-color: #EBD300 !important;
  }

  .p-splitbutton-menubutton:focus {
    background-color: #EBD300 !important;
    border: 1px solid #00CBDE !important;
  }

  .p-splitbutton-menubutton:active:not([disabled]) {
    background-color: #B8A200 !important;
    border: none !important;
  }
}

.disabled-split-btn {
  .p-splitbutton-menubutton {
    background-color: gray !important;
    box-shadow: 0px 0px 1px 1px grey !important;
  }
}

/************************Split Button Styles of Beon ******************************/

/************************Action Link Styles of Beon ******************************/
.beon-action-link {
  .text-link {
    font-size: 14px;
    font-family: GraphikRegular;
    color: #009AAB !important;
    cursor: pointer !important;
    text-decoration: none !important;
  }

  .text-link:focus:active {
    border: 1px #005D6B solid !important;
  }

  .action-link:focus:active {
    border: 1px solid #005D6B !important;
  }

  .action-link {
    color: #007A8A !important;
    font-size: 14px !important;
    font-family: GraphikMedium !important;
    text-decoration: none !important;
    cursor: pointer !important;
  }

  .action-link:hover {
    font-family: GraphikMedium;
    color: #005D6B !important;
    text-decoration: underline !important;
  }

  .action-link:focus {
    font-family: GraphikMedium;
    color: #005D6B !important;
    text-decoration: underline !important;
    border: 1px solid #005D68 !important;
  }

}

.disabled {
  pointer-events: none;
  font-size: 14px !important;
  font-family: GraphikMedium !important;
  cursor: default;
  color: #8D8D8D !important;
  text-decoration: none;

}

/************************Action Link Styles of Beon ******************************/

/***********************Beon Action Link Button of Beon**************************/
.beon-ActionLink-btn {
  .p-button-label {
    font-family: GraphikMedium;
    font-size: 16px;
    color: #009AAB !important;
  }

  .p-actionlink-btn {
    width: $beon-clear-btn-width;
    border-radius: 100px;
    color: #007A8A !important;
    background-color: transparent !important;
    border: none !important;
    box-shadow: none;
    height: 40px;
    border: 1px solid #007A8A !important;
  }

  .p-actionlink-btn:hover {
    color: #005D6B !important;
    border: 1px solid #007A8A !important;

  }

  .p-actionlink-btn:focus {
    border: 1px solid #007A8A !important;
  }

  .p-button:focus {
    box-shadow: 0 0 0 0 transparent !important;
  }

}

.p-actionlink20-btn {
  .p-button-label {
    color: #161616 !important;
    font-size: 14px !important;
    font-family: GraphikMedium;
  }

  width: 25% !important;
  height: 40px;
  border-radius: 3rem !important;
  box-shadow: none;
  background-color: #FFFFFF !important;
  border: 1px solid #8D8D8D !important;
  box-shadow: 0 0 0 0 transparent !important;


}

.p-actionlink20-btn:hover {
  background-color: #F4F4F4 !important;
}

/***********************Beon Action Link Button of Beon**************************/

/************************Text Link Styles of Beon ******************************/
.beon-icon-button {
  .default-icon-btn {
    .pi-check {
      color: grey !important;
    }
  }

  .default-icon-btn:disabled {
    background-color: none !important;
  }

  .p-button.p-button-icon-only.p-button-rounded:active {
    background-color: #A8A8A8 !important;
    box-shadow: 0 0 0 0 transparent !important;
  }

  .p-button.p-button-icon-only.p-button-rounded:hover:not([disabled]) {
    background-color: #EEEEEE !important;
  }

  .p-button.p-button-icon-only.p-button-rounded {
    box-shadow: 0 0 0 0 transparent !important;
  }

  .p-button.p-button-icon-only.p-button-rounded:focus {
    box-shadow: 0 0 0 1px #00CBDE !important;
  }
}

/************************Text Link Styles of Beon ******************************/

/************************Select Button Styles of Beon ******************************/
.beon-select-btn {
  .select-btn {
    .p-button:hover {
      background-color: #E3FDFF !important;
      border: #00F0FF 1px solid !important;
    }


    .p-highlight {
      background-color: #E3FDFF !important;
      border: #82F7FF 1px SOLID !important;
    }

    .p-button:focus {
      box-shadow: 0 0 0 0.1rem #a6d5fa !important;
    }

    .p-buttonset .p-button:first-of-type {
      border-top-left-radius: 1rem !important;
      border-bottom-left-radius: 1rem !important;
      background-color: none !important;

    }

    .p-buttonset .p-button:last-of-type {
      border-top-right-radius: 1rem !important;
      border-bottom-right-radius: 1rem !important;
    }
  }

}

/************************Select Button Styles of Beon ******************************/

/************************Input Text Box Styles of Beon ******************************/
.p-inputtext {
  color: #161616 !important;
}

.p-inputtext.ng-dirty.ng-invalid {
  border-color: #AB000E !important;
}

.p-float-label>.ng-invalid.ng-dirty+label {
  color: #AB000E !important;
}

.beon-input-textbox {

  .p-inputtext {
    color: #161616;
    width: 100% !important;
    border: 1px solid #C6C6C6;
    font-size: 14px !important;

  }

  .p-float-label input:focus~label {
    top: 0 !important;
    font-family: GraphikRegular;
    background-color: #FFFFFF !important;
    color: #007A8A !important;
    padding-left: 3px;
    padding-right: 3px;
    font-size: 12px;
  }

  .p-float-label input.p-filled~label {
    top: 0.1rem;
    background: #FFFFFF;
    font-family: GraphikRegular;
    color: #6F6F6F !important;
    font-size: 12px;
    padding-left: 3px;
    padding-right: 3px;
  }

  .p-float-label input:focus.p-filled~label {
    color: #007A8A !important;
  }

  .p-inputtext:hover {
    border: 1px solid #6F6F6F !important;
  }

  .p-inputtext:focus {
    border: 2px solid #007A8A !important;
    box-shadow: 0 0 0 0 transparent !important;
  }

  .p-inputtext:disabled {
    border: 1px solid #E0E0E0 !important;
    background-color: #F4F4F4;
    color: #8d8d8d !important;
  }

  .p-inputtext:disabled~.beon-label {
    color: #8D8D8D !important;
  }
}

.inputboxerror {
  .p-inputtext {
    border: 2px solid #AB000E;
    box-shadow: 0 0 0 0 transparent !important;
  }

  .p-inputtext:hover {
    border: 2px solid #AB000E !important;
  }

  .p-inputtext:focus {
    border: 2px solid #AB000E !important;
  }

  .p-float-label input:focus~label {
    color: #AB000E !important;
  }


}

/************************Input Text Box Styles of Beon ******************************/

/************************Tab view Styles of Beon ******************************/
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  color: #007A8A !important;
  box-shadow: none !important;
  font-family:GraphikMedium;
  font-size: 14px;
  font-weight: normal !important;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link:active {
  color: #007A8A !important;

}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link:hover {
  .p-tabview-title {
    color: #007A8A !important;
  }
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  color: #6F6F6F !important;
  font-size: 14px;
  font-family: GraphikMedium;
  font-weight: normal !important;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:hover {
  .p-tabview-title {
    color: #161616 !important;
  }
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.1rem transparent !important;
}

/************************Tab view Styles of Beon ******************************/

/************************DropDown Styles of Beon ******************************/
.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  font-size: 14px !important;
  color: #161616 !important;
}

.p-beon-dropdown {

  .p-dropdown {
    width: $p-dropdown-width;
    border: 1px solid #C6C6C6;
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    color: #161616;
    background-color: #F4F4F4 !important;
    font-size: 14px;
  }

  .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
    color: #161616 !important;
    background: #F4F4F4 !important;
  }

  .p-dropdown-item-group {
    color: #161616 !important;
    font-size: 14px !important;
  }

  .p-inputtext {
    color: #161616 !important;
  }

  .p-dropdown-item {
    color: #161616 !important;
    font-size: 14px !important;
  }

  .p-dropdown:hover {
    border: 1px solid #6F6F6F !important;
  }

  .p-dropdown:active {
    border: 2px solid #007A8A !important;
  }

  .p-float-label .p-inputwrapper-filled~label {
    top: 0 !important;
    font-size: 12px !important;
    font-family: GraphikRegular;
    background: white !important;
    color: #6f6f6f !important;
    padding-left: 3px;
    padding-right: 3px;
  }

  .p-float-label .p-inputwrapper-focus~label {
    top: 0 !important;
    font-size: 12px !important;
    font-family: GraphikRegular;
    background: white !important;
    color: #007A8A !important;
    padding-left: 3px;
    padding-right: 3px;
  }

  .p-dropdown:not(.p-disabled).p-focus {
    border: 2px solid #007A8A !important;
    box-shadow: 0 0 0 0 transparent !important;
  }

  .p-dropdown-empty-message {
    color: #161616 !important;
    font-size: 14px !important;
  }

  .p-disabled {

    .p-inputtext {
      background: #F4F4F4 !important;
      border: #E0E0E0 !important;
      border-right: none !important;
      color: #8D8D8D !important;
    }

    .p-dropdown-trigger {
      background: #F4F4F4 !important;
      border: #E0E0E0 !important;
      border-left: none !important;
    }
  }

  .p-disabled~.p-beon-dropdown .p-float-label .p-inputwrapper-filled~label {
    color: #8D8D8D !important;
  }

  .p-disabled.p-dropdown {
    background: #F4F4F4 !important;
  }

  .p-disabled {
    .p-inputtext {
      background: #F4F4F4 !important;
    }

    .p-dropdown-trigger {
      background: #F4F4F4 !important;
    }
  }

  .p-disabled.p-dropdown {
    background: #F4F4F4 !important;
  }
}

.dropdownerror {
  .p-dropdown {
    border: 2px solid #AB000E !important;
  }

  .p-dropdown {
    border: #AB000E;
  }

  .p-dropdown:not(.p-disabled).p-focus {
    border: 2px solid #AB000E !important;
  }

  .p-float-label .p-inputwrapper-focus~label,
  .p-float-label .p-inputwrapper-filled~label {
    color: #AB000E !important;
  }

  .p-dropdown:hover {
    border: 2px solid #AB000E !important;
  }
}


/************************DropDown Styles of Beon ******************************/

/************************ToolTip Styles of Beon ******************************/
.p-tooltip-text {
  background-color: black !important;
  //text-transform: capitalize;
  color: $ToolTip-color !important;
  border: $ToolTip-bgcolor 1px solid !important;
  padding-top: $ToolTip-padTop !important;
  padding-bottom: $ToolTip-padBot !important;
  border-radius: 1px !important;
}

.p-tooltip-arrow {
  visibility: hidden !important;
}

/************************ToolTip Styles of Beon ******************************/

/************************CheckBox Styles of Beon ******************************/
.p-checkbox .p-checkbox-box {
  border: 2px solid #c6c6c6;
  background: #ffffff;
  width: 18px;
  height: 18px;
  border-radius: 2px;
}

.p-checkbox .p-checkbox-box .p-checkbox-icon {
  font-size: 10px !important;
  padding-top: 2px !important;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border: 2px solid #6F6F6F !important;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  box-shadow: 0 0 0 0.2rem solid #00cbde !important;
  border-color: 2px solid #6F6F6F;
}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: #007a8a !important;
  background: #007a8a !important;
}

.p-checkbox .p-checkbox-box.p-highlight:hover {
  border-color: #005d6b !important;
  background: #005d6b !important;
}

.p-checkbox .p-checkbox-box.p-highlight:focus {
  border-color: 2px solid #005d6b !important;
  background: 3px solid #00cbde !important;
}

.p-checkbox.p-checkbox-box.p-label-disabled {
  #disabled {
    color: grey !important;
  }
}

.checkboxerror {
  .p-checkbox .p-checkbox-box {
    box-shadow: 0 0 0 1px red !important;
  }
}

/************************CheckBox Styles of Beon ******************************/

/************************Radio Button Styles of Beon ******************************/
.p-radio-btn {
  .p-radiobutton .p-radiobutton-box.p-highlight {
    background-color: #009AAB !important;
    // border: #005D6B !important;
    box-shadow: 0 0 0 1px #009AAB !important;

  }

  .p-radiobutton-icon {
    background-color: #009AAB !important;
  }

  .p-radiobutton .p-radiobutton-box:hover {
    // background-color: #009AAB !important;
    // border: #005D6B !important;
    box-shadow: 0 0 0 1px #009AAB !important;
  }

  .Radiobuttonerror {
    .p-radiobutton .p-radiobutton-box {
      box-shadow: 0 0 0 1px red !important;
    }
  }
}

/************************Radio Button Styles of Beon ******************************/

/************************Confirmation popup Styles of Beon ******************************/
.confirmpopup {
  .p-dialog .p-dialog-footer button {
    .p-button-label {
      font-size: 14px;
      font-family: GraphikMedium;
      color: #161616 !important;
    }

    border-radius: 3rem;
    color:#161616 !important;
    background: #FFEB00 !important;
    box-shadow: 0 0 0 0 #FFEB00 !important;
    border: 1px solid #FFEB00 !important;
    transition: background-color 0.2s,
    color 0.2s,
    border-color 0.2s,
    box-shadow 0.2s !important;

  }

  .p-dialog .p-dialog-footer button:hover {
    background: #EBD300 !important;
  }

  .p-dialog.p-confirm-dialog .p-confirm-dialog-message {
    font-size: 14px !important;
    font-family: GraphikRegular;
    color: #161616 !important;
  }

  .p-dialog .p-dialog-header {
    height: $p-dialog-header-height !important;
  }

  .p-dialog .p-dialog-header .p-dialog-title {
    font-family: GraphikMedium;
    font-size: 20px !important;
    color: #161616;
  }

  .p-dialog .p-dialog-content {
    padding-top: 15px !important;
  }

  .p-button-text.p-confirm-dialog-reject.p-button.p-component {
    .p-button-label {
      color: #161616 !important;
      font-size: 14px !important;
      font-family: GraphikMedium;
    }

    border-radius: 3rem;
    box-shadow: none;
    background-color: $beon-clear-btn-color !important;
    border: 1px solid #8D8D8D !important
  }

  .p-button-text.p-confirm-dialog-reject.p-button.p-component:hover {
    background-color: #F4F4F4 !important;

  }

  .p-dialog {
    width: 400px !important;
  }

  .p-dialog .p-dialog-content {
    padding: 0 0.5rem 1rem !important;
  }

  .p-button .p-button-icon-left {
    display: none !important;
  }
}

/************************Confirmation popup Styles of Beon ******************************/

/************************Mediumdialogbox Styles of Beon *********************************/
.Mediumdialogbox {
  .p-dialog {
    width: 700px !important;
  }

  .p-dialog .p-dialog-footer button {
    border-radius: 3rem;
    color: #161616 !important;
    background: $beon-primary-btn-color !important;
    box-shadow: 0 0 0 0 $beon-primary-btn-color !important;
    border: 1px solid $beon-primary-btn-color !important;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s !important;
  }

  .p-dialog .p-dialog-footer button:hover {
    border-radius: 3rem !important;
    background: $beon-primary-btn-hover-focus-color !important;
    box-shadow: 0 0 0 0 $beon-primary-btn-hover-focus-color !important;
    border: 1px solid $beon-primary-btn-hover-focus-color !important;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s !important;

  }

}

/************************Mediumdialogbox Styles of Beon *********************************/

/************************Fulldialog box Styles of Beon **********************************/
.Largedialogbox {
  .p-dialog {
    width: 1000px !important;
  }

  .p-dialog .p-dialog-footer button {
    border-radius: 3rem;
    color: #161616 !important;
    background: $beon-primary-btn-color !important;
    box-shadow: 0 0 0 0 $beon-primary-btn-color !important;
    border: 1px solid $beon-primary-btn-color !important;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s !important;
  }

  .p-dialog .p-dialog-footer button:hover {
    border-radius: 3rem !important;
    background: $beon-primary-btn-hover-focus-color !important;
    box-shadow: 0 0 0 0 $beon-primary-btn-hover-focus-color !important;
    border: 1px solid $beon-primary-btn-hover-focus-color !important;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s !important;

  }
}

/************************Fulldialog box Styles of Beon **********************************/

/************************Calender Styles of Beon ******************************/
.beon-calender {
  .p-calendar {
    width: 100% !important;
  }

  .p-datepicker-today>span.p-highlight {
    background: #005D6B !important;
    color: WHITE !important;
    box-shadow: none !important;
  }

  .p-datepicker>span.p-highlight {

    background: #005D6B !important;
  }

  .p-button {
    width: $beon-primary-btn-width;
    background-color: $beon-primary-btn-color !important;
    border: 1px solid $beon-primary-btn-color;
    border-radius: 3rem;
  }

  .p-button:hover:enabled {
    background-color: $beon-primary-btn-hover-focus-color !important;
  }
}

/************************Calender Styles of Beon ******************************/

/************************Input Switch Styles of Beon ******************************/
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background-color: #182853 !important;
}

/************************Text Link Styles of Beon ******************************/

/************************Accordian Styles of Beon ******************************/
.p-accordion .p-accordion-header .p-accordion-header-link {
  border: 1px solid #c6c6c6 !important;
}

.beon-accordian {

  .p-accordion{

    p-accordiontab:nth-of-type(4n+1), p-accordiontab:nth-of-type(4n+1) + * {
      .p-tabview .p-tabview-nav li .p-tabview-nav-link{
        border-bottom-width: 2px !important;
      }
    }
  }
  .p-accordion-header-link {
    flex-direction: row-reverse;
  }

  .pi-chevron-right:before {
    content: "\e902";
    color: #6F6F6F !important;
  }

  .pi-chevron-down:before {
    content: "\e903";
    color: #6F6F6F !important;
  }

  .p-accordion .p-accordiontab .p-accordion-header .p-accordion-header-link {
    //background: $white-color;
    font-family: GraphikRegular;
    font-size: 16px !important;
  }

  .p-tabview-title {
    font-size: 14px !important;
    font-family: GraphikMedium !important;

  }

  .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    font-size: 14px !important;
    color: #6F6F6F !important;
  }

  .beon-accordian .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    font-size: 14px !important;
  }
  .p-accordion .p-accordion-content {
    border-radius: 0px 0px 8px 8px !important;
    border-color: #C6C6C6 !important;
    background: #FAFAFA;

  }

  .p-accordion p-accordiontab .p-accordion-header .p-accordion-header-link {
    // border-radius: 8px !important;
    border-color: #E0E0E0 !important;
  }

  .p-accordion p-accordiontab:not(:first-child):not(:last-child) .p-accordion-header .p-accordion-header-link{
    border-radius: 0px !important;
    border-bottom-color: transparent !important;
  }
  .p-accordion p-accordiontab:last-child .p-accordion-header:not(.p-highlight) .p-accordion-header-link{
    border-radius: 0px 0px 8px 8px ;
    border-bottom-color: #E0E0E0 !important;
  }
  .p-accordion p-accordiontab:first-child .p-accordion-header .p-accordion-header-link {
    border-radius: 8px 8px 0px 0px !important;
    border-bottom-color: transparent !important;
  }

  .p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
    background: #FAFAFA !important;
    border: 1px solid #C6C6C6 !important;
    border-radius: 8px 8px 0px 0px !important;
    border-bottom: none !important;
  }

  .p-accordion p-accordiontab:not(:first-child) .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
    background: #FAFAFA !important;
    border: 1px solid #C6C6C6 !important;
    border-radius: 0px 0px 0px 0px !important;
    border-bottom: none !important;
  }
  .p-accordion p-accordiontab:not(:first-child) .p-accordion-content{
    border-radius: 0px 0px 0px 0px !important;
  }
  .p-accordion p-accordiontab:last-child .p-accordion-content{
    border-radius: 0px 0px 8px 8px !important;
  }
  .p-accordion p-accordiontab:first-child .p-accordion-content{
    border-radius: 0px 0px 0px 0px !important;
  }
  .p-accordion p-accordiontab:only-child .p-accordion-content{
    border-radius: 0px 0px 8px 8px !important;
  }
  .p-accordion p-accordiontab:only-child .p-accordion-header .p-accordion-header-link {
    border-radius: 8px 8px 8px 8px !important;
    border-bottom-color: transparent !important;
  }

  .p-datatable .p-datatable-header {
    border: none !important;
  }

  .p-tabview .p-tabview-panels {
    background: #FAFAFA;
    color: #161616;
    font-size: 14px;
    font-family: 'GraphikRegular';

  }

  .p-tabview .p-tabview-nav {
    background: #FAFAFA !important;
  }

  .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background: #FAFAFA;

    @media screen and (min-width:576px) and (max-width: 2400px) {
      font-size: getVwValue($global-font-size-no) * 1vw !important;
    }
  }

  .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    // border-bottom-width: 3px !important;
    background: #FAFAFA;

    @media screen and (min-width:576px) and (max-width: 2400px) {
      font-size: getVwValue($global-font-size-no) * 1vw !important;
    }
  }

  .p-tabview .p-tabview-nav .p-highlight .p-tabview-nav-link {
    color: #007A8A !important;
    box-shadow: none !important;
    font-family: GraphikRegular;
    font-size: 14px;
  }

  .p-tabview .p-tabview-nav .p-highlight .p-tabview-nav-link:active {
    border-color: #009AAB !important;
    color: #009AAB !important;
  }

  .p-tabview .p-tabview-nav .p-highlight .p-tabview-nav-link:focus {
    border-color: #009AAB !important;
    color: #009AAB !important;
  }

  .p-tabview .p-tabview-nav li .p-tabview-nav-link:hover {
    background: #FAFAFA !important;
  }

  .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link:hover {
    background: #FAFAFA;
  }



  .p-accordion-header-link {
    background-color: #FAFAFA !important;
    background: white !important;
  }

  .p-accordion .p-accordion-header .p-accordion-header-link {
    font-size: 14px !important;
    color: #161616 !important;
    font-family: GraphikMedium !important;
    font-weight: 500 !important;
  }

  .p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
    box-shadow: none !important;
  }

  .p-accordion-header-link:focus {
    z-index: 0 !important;
  }

  .p-accordion .p-accordiontab .p-accordion-tab {
    padding-bottom: $p-accordian-pad-btm;
  }

  .p-accordion .p-accordiontab .p-accordion-content {
    background: white !important;
    //border-bottom: 2px solid $discovery-search-btn-color;
  }

  .p-accordion p-accordiontab .p-accordion-tab {
    // padding-bottom: $p-accordian-pad-btm !important;
  }

}

/************************Accordian Styles of Beon ******************************/

/************************Navbar Styles of Beon *********************************/
// .navbar {
//   font-size: $nav-font-size;
//   font-family: GraphikRegular;
//   background-color: #000000 !important;
// }
.navbar {
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
}

.beon-header-focused {
  color: #ffffff !important;

  .nav-link {
    color: #ffffff !important;
  }
}

.navbar-dark .navbar-nav .nav-link {
  color: #a8a8a8;
  font-family: GraphikRegular;
  padding: 10px 8px 8px !important;
}

.navbar-dark .navbar-nav .nav-link.beon-header-focused {
  color: #ffffff;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 25%) !important;
}


/************************Navbar Styles of Beon ******************************/

/************************p-card Styles of Beon ******************************/
.p-card {
  width: 100% !important;
  border-radius: 1rem !important;
  box-shadow: 0 0 0 0 grey opacity($color: 16%);
  height: $card-height !important;
  box-shadow: 0 3px 2px -1px gray, 0 1px 2px 0 gray,
    0 1px 3px 0 gray !important;

  .p-card-title {
    font-family: GraphikMedium;
    color: $card-title-color;
  }

  .p-card-subtitle {
    font-family: GraphikMedium;
    color: $card-subtitle-color;
  }

  .p-card-footer {
    font-family: GraphikRegular;
    color: $card-footer-color;
  }

  .p-card-content {
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  }
}

/************************p-card Styles of Beon ******************************/

/********************************************common css for mandantory field**************/
.mandatory::after {
  content: $mandatory-content;
  font-size: $mandatory-font-size;
  color: grey;
}

/********************************************common css for mandantory field**************/

/*************************************Global Styles for Label******************************/
.beon-label {
  font-size: 14px;
  font-family: GraphikRegular;
  color: #6F6F6F !important;
}

.beon-black-label {
  font-size: 14px;
  font-family: GraphikRegular;
  color: #161616 !important;
}

/*************************************Global Styles for Label******************************/

/**************************Text Area Styles of Beon **********************************************/
.Beon-text-area {

  textarea {
    font-size: 14px !important;
    color: #161616 !important;
    border: #c6c6c6 1px solid !important;
    padding: 0.5rem !important;
    width: 100% !important;
  }

  textarea:hover {

    border: #a8a8a8 1px solid !important;
  }

  textarea:active {
    border-color: #007A8A 1px solid !important;

    box-shadow: 0 0 0 0.1rem #007A8A !important;
  }

  textarea:focus {
    border-color: #007A8A 1px solid !important;
    box-shadow: 0 0 0 0.1rem #007A8A !important;
  }


  .p-float-label textarea:focus~label {
    top: 0;
    background: white;
    font-size: 12px;
    color: #007A8A;
    font-weight: 400;
    padding-left: 3px;
    padding-right: 3px;

  }

  .p-float-label textarea.p-filled~label {
    top: 0;
    color: #007A8A !important;
    font-size: 12px;
    background: white !important;
    padding-left: 3px;
    padding-right: 3px;
  }
}

/**************************Text Area Styles of Beon **********************************************/

/**************************Auto Complete Styles of Beon **********************************************/

.beon-autocomplete {
  .p-autocomplete {
    width: 100% !important;
    font-size: 14px !important;
    height: 40px !important;
  }

  .p-inputtext {
    color: #161616 !important;
    font-size: 14px !important;
  }

  .p-autocomplete-item-group {
    margin: 0;
    padding: 0.75rem 1rem;
    color: #161616 !important;
    font-weight: 500;
    font-size: 14px !important;
    font-family: GraphikRegular;
  }

  .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
    color: #161616;
    font-size: 14px !important;
    padding-left: 25px !important;
  }

  .p-float-label .p-inputwrapper-focus~label {
    top: 0;
    background-color: white !important;
    font-family: GraphikRegular;
    color: #007A8A !important;
    font-size: 12px;
    padding-left: 3px;
    padding-right: 3px;
  }

  .p-float-label .p-inputwrapper-filled.p-inputwrapper-focus~label {
    top: 0;
    background-color: white !important;
    font-family: GraphikRegular;
    color: #007A8A !important;
    font-size: 12px;
    padding-left: 3px;
    padding-right: 3px;
  }

  .p-float-label .p-inputwrapper-filled~label {
    top: 0;
    font-size: 12px;
    background: white;
    font-family: GraphikRegular;
    color: #6F6F6F !important;
    padding-left: 3px;
    padding-right: 3px;
  }

  .pi-chevron-down:before {
    color: #6F6F6F !important;
    vertical-align: middle;
    padding-left: 4px;
  }

  .p-element.p-autocomplete-dropdown.p-button.p-component.p-button-icon-only {
    background-color: transparent;
    border: 1px solid #C6C6C6;
    border-left: none !important;
    height: 39px !important;
  }

  .p-autocomplete-input.p-inputtext.p-component.p-autocomplete-dd-input {
    border: 1px solid #C6C6C6;
    border-right: none !important;
    height: 39px !important;
  }

  .p-autocomplete-input.p-inputtext:disabled.p-component.p-autocomplete-dd-input {
    border: 1px solid #E0E0E0 !important;
    border-right: none !important;
    background: #F4F4F4 !important;
  }

  .p-autocomplete-input.p-inputtext:disabled.p-component.p-autocomplete-dd-input~.p-element.p-autocomplete-dropdown.p-button.p-component.p-button-icon-only {
    border: 1px solid #E0E0E0 !important;
    background: #F4F4F4 !important;
    border-left: none !important;
  }

  .p-autocomplete-input.p-inputtext.p-component.p-autocomplete-dd-input:hover {
    border: 1px solid #6F6F6F;

  }

  .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-empty-message {
    color: #161616 !important;
    font-size: 14px !important;
    font-family: GraphikRegular !important;
  }

  .p-autocomplete-input.p-inputtext.p-component.p-autocomplete-dd-input:hover~.p-element.p-autocomplete-dropdown.p-button.p-component.p-button-icon-only {
    border: 1px solid #6F6F6F;
  }

  .p-autocomplete-input.p-inputtext.p-component.p-autocomplete-dd-input:focus {
    border: 2px solid #007A8A;
    box-shadow: 0 0 0 0 transparent !important;
  }

  .p-autocomplete-input.p-inputtext.p-component.p-autocomplete-dd-input:focus~.p-element.p-autocomplete-dropdown.p-button.p-component.p-button-icon-only {
    border: 2px solid #007A8A;
    box-shadow: 0 0 0 0 transparent !important;
  }

  .p-button:focus {
    box-shadow: 0 0 0 0 transparent !important;
  }

  .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item:hover {
    color: #161616;
    background: #F4F4F4 !important;
  }
}

.beon-autocomplete-error {
  .p-element.p-autocomplete-dropdown.p-button.p-component.p-button-icon-only {
    border: 2px solid #AB000E;
  }

  .p-element.p-autocomplete-dropdown.p-button.p-component.p-button-icon-only:focus {
    border: 2px solid #AB000E;
  }

  .p-element.p-autocomplete-dropdown.p-button.p-component.p-button-icon-only:hover {
    border: 2px solid #AB000E;
  }

  .p-float-label .p-inputwrapper-focus~label {
    color: #AB000E;
  }

  .p-autocomplete-input.p-inputtext.p-component.p-autocomplete-dd-input {
    border: 2px solid #AB000E;
  }

  .p-autocomplete-input.p-inputtext.p-component.p-autocomplete-dd-input~.p-element.p-autocomplete-dropdown.p-button.p-component.p-button-icon-only {
    border: 2px solid #AB000E;
  }

  .p-autocomplete-input.p-inputtext.p-component.p-autocomplete-dd-input:hover {
    border: 2px solid #AB000E;
  }

  .p-autocomplete-input.p-inputtext.p-component.p-autocomplete-dd-input:hover~.p-element.p-autocomplete-dropdown.p-button.p-component.p-button-icon-only {
    border: 2px solid #AB000E;
  }

  .p-autocomplete-input.p-inputtext.p-component.p-autocomplete-dd-input:focus {
    border: 2px solid #AB000E;
  }

  .p-autocomplete-input.p-inputtext.p-component.p-autocomplete-dd-input:focus~.p-element.p-autocomplete-dropdown.p-button.p-component.p-button-icon-only {
    border: 2px solid #AB000E;
  }

  .p-float-label .p-inputwrapper-focus~label {
    color: #AB000E !important;
  }

  .p-float-label .p-inputwrapper-filled~label {
    color: #AB000E !important;
  }
}

/**************************AutoComplete Styles of Beon **********************************************/

/************************************** Common css for Discovery-multiselect ********************************/
.discovery-multiselect {
  .p-multiselect {
    width: $p-multiselect-width;

  }

  .p-float-label .p-inputwrapper-filled~label {
    top: 0;
    font-size: 12px;
    background: white;
    color: #005D6B !important;
    padding-left: 3px;
    padding-right: 3px;
  }

}



.Data-Discovery-multiselect {
  .p-multiselect {
    width: $p-multiselect-width;

    .p-multiselect-trigger {
      background: $discovery-search-btn-color !important;
    }

  }

}


.beon-multiselect {

  .p-multiselect,
  .p-treeselect {
    width: $p-multiselect-width;
  }

  .p-multiselect:hover {
    border: 1px solid #8d8d8d !important;
  }

  .p-multiselect.p-component.p-focus {
    border: 2px solid #007A8A !important;
    box-shadow: 0 0 0 0 transparent !important;
  }

  .p-multiselect:active {
    border: 2px solid #007A8A !important;
  }

  .p-multiselect.p-disabled {
    border: 0px solid #E0E0E0 !important;

    .p-multiselect-label-container {
      border: 1px solid #E0E0E0 !important;
      background: #F4F4F4 !important;
      border-right: none !important;

      .p-multiselect-label-container~.beon-label {
        color: #8D8D8D !important;
      }
    }

    .p-multiselect-trigger {
      border: 1px solid #E0E0E0 !important;
      background: #F4F4F4 !important;
      border-left: none !important;
    }
  }

  .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
    color: #161616 !important;
    font-size: 14px !important;
  }

  .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
    color: #161616 !important;
    font-size: 14px !important;
  }

  .p-multiselect.p-multiselect-chip .p-multiselect-token {
    background: #EEEEEE;
    color: #161616 !important;
    font-size: 14px !important;
    font-family: GraphikRegular;
  }

  .p-multiselect-label {
    color: #161616 !important;
    font-size: 14px !important;
  }

  .pi-times-circle:before {
    content: "\e90b";
  }

  .p-multiselect.p-multiselect-chip .p-multiselect-token .p-multiselect-token-icon {
    font-size: 12px !important;
  }

  .p-float-label .p-inputwrapper-focus~label {
    top: 0;
    font-size: 12px;
    background: white;
    color: #007A8A !important;
    font-family: GraphikRegular;
  }

  .p-float-label .p-inputwrapper-filled.p-inputwrapper-focus~label {
    top: 0;
    font-size: 12px;
    background: white;
    color: #007A8A !important;
    font-family: GraphikRegular;
  }

  .p-float-label .p-inputwrapper-filled~label {
    top: 0;
    font-size: 12px;
    background: white;
    color: #6f6f6f !important;
    font-family: GraphikRegular;
  }

  .p-multiselect-item.p-highlight {
    background-color: transparent !important;
  }

  .p-multiselect-item.p-highlight:hover {
    background-color: #F4F4F4 !important;

  }

  .p-multiselect-empty-message {
    font-size: 14px !important;
    color: #161616 !important;

  }
}

/************************************** Common css for Discovery-multiselect *******************************/

/**************************************Common Styles for check-box*****************************************/
.custom_checkbox {
  position: relative;

  .styled-checkbox {
    position: absolute; // take it out of document flow
    opacity: 0; // hide it


    &+label {
      position: relative;
      cursor: pointer;
      padding: 0;
    }

    // Box.
    &+label:before {
      content: "";
      margin-right: 10px;
      display: inline-block;
      vertical-align: text-top;
      width: 19px;
      height: 19px;
      background: white;
      border: 2px solid #c6c6c6;
      border-radius: 2px;
    }

    // Box hover
    &:hover+label:before {
      border: 2px solid #6F6F6F;
    }

    &:focus+label:before {
      border: 2px solid #6F6F6F;
      outline: 3px solid #00cbde;
    }

    // Box checked
    &:checked+label:before {
      background: #007a8a;
      border: #007a8a;
      width: 19px;
      height: 19px;

    }

    &:checked+label:hover:before {
      background: #005d6b;
    }

    &:checked+label:focus::before {
      background: #005d6b;
      outline: 3px solid #00cbde;
    }

    // Disabled state label.
    &:disabled+label {
      color: #b8b8b8;
      cursor: read-only;
    }

    // Disabled box.
    &:disabled+label:before {
      box-shadow: none;
      background: #ddd;
    }

    &:disabled+label:hover:before {
      box-shadow: none;
      background: #ddd;
      border: 3px solid #c6c6c6;
    }

    &:disabled+label:checked:before {
      box-shadow: none;
      background: #ddd;
      border: 3px solid #c6c6c6;
      outline: none;
    }

    // Checkmark.
    &:checked+label:after {
      content: "";
      display: block;
      position: absolute;
      top: 4px;
      left: 7px;
      width: 5px;
      height: 10px;
      border: solid #ffffff;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }
}

.checkbox-label {
  font-size: 14px;
  font-family: GraphikRegular;
  color: #161616;
}

/**************************************Common Styles for check-box*****************************************/

/**************************************Common Styles for radio-button*****************************************/

.control-group {
  .custom_radio {
    margin: 20px;
  }

  .custom_radio input[type="radio"] {
    display: none;
  }

  .custom_radio input[type="radio"]+label {
    position: relative;
    display: inline-block;
    padding-left: 1.5em;
    margin-right: 2em;
    cursor: pointer;
    line-height: 1em;
  }

  .custom_radio input[type="radio"]+label:before,
  .custom_radio input[type="radio"]+label:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 1em;
    height: 1em;
    text-align: center;
    color: white;
    font-family: Times;
    border-radius: 50%;
  }

  .custom_radio input[type="radio"]+label:before {
    border: 3px solid #c6c6c6;
  }

  .custom_radio input[type="radio"]+label:hover:before {
    border: 3px solid #a8a8a8;
  }

  .custom_radio input[type="radio"]:checked+label:before {
    border: 3px solid #005d6b;
    box-shadow: inset 0 0 0 0.1em white, inset 0 0 0 4em #005d6b;
  }

  .custom_radio input[type="radio"]:checked+label:hover:before {
    border: 3px solid #004a59;
    box-shadow: inset 0 0 0 0.1em white, inset 0 0 0 4em #004a59;
    outline: 3px solid #00cbde;
  }
}

/**************************************Common Styles for radio-button*****************************************/

/**************************************Common styles for switch***********************************************/

.form-switch .form-check-input:checked {
  background-image: url(../src/assets/Images/Frame.svg) !important;
  background-size: 1rem !important;
  border: 2px solid #007A8A !important;
  background-color: #007A8A !important;

}
.form-switch .form-check-input:checked:hover {
  background-color: #005D6B !important;
}
.form-switch .form-check-input:checked:focus{
  background-color: #005D6B !important;
  border: 2px solid #00CBDE !important;
}
.form-switch .form-check-input:checked:active{
  background-color: #004A59 !important;
  border:none !important;
}


.form-switch .form-check-input{
  background-image: url(../src/assets/Images/Handle.svg) !important;
  background-size: 1rem !important;
  border: 2px solid #A8A8A8 !important;
  background-color: #A8A8A8 !important;

}
.form-switch .form-check-input:focus {
  box-shadow: 0 0 0 0 transparent !important;
 background-color:#8D8D8D !important;
 border: 2px solid #00CBDE !important;
}
.form-switch .form-check-input:hover {
background-color:#8D8D8D !important;
}
.form-switch .form-check-input:active {
 background-color:#6F6F6F !important;
 border: none !important;
}


@media (max-width: 767.98px) {
  .filteraction {
    display: flex;
    gap: 0.5rem;
  }

  .clearbutton {
    display: flex;
    justify-content: center;
  }

  .favcompanies {
    display: flex;
  }
}

.container {
  max-width: 100% !important;
  padding-left: 32px !important;
  padding-right: 32px !important;
  //background-color:#FFFFFF !important;



}


/***********************************Styles for Download icon*********************************************/
.pi-download:before {
  color: #161616;
}

/***********************************Styles for Download icon*********************************************/

/************************************Styles for Text Danger**********************************************/
.text-danger {
  color: #AB000E !important;
  font-size: 12px;
  font-family: GraphikRegular;
}

/************************************Styles for Text Danger**********************************************/

/************************************Paginator Styles***************************************************/
.p-paginator-current {
  color: #161616 !important;
  font-size: 14px;
  font-family: 'GraphikRegular';
}

/************************Global Styles of Beon ******************************/

/************************************Message Styles***************************************************/
.p-message .p-message-summary {
  font-family: GraphikMedium;
  font-size: 14px;
  color: #AB000E;
  font-weight: normal !important;
}

.p-message .p-message-detail {
  font-family: GraphikRegular;
  font-size: 14px;
  color: #AB000E;
}

.alert-danger {
  font-family: GraphikRegular;
  font-size: 14px;
  color: #AB000E !important;
}

/************************************Message Styles***************************************************/

/***********************************Styles for unauthorized popup button*****************************/
.colactivestyles {
  color: #161616;
  font-size: 14px;
  font-family: GraphikRegular;
  font-weight: 300 !important;
  background-color: #CCFFCB;
  text-align: center;
  padding: 5px 8px 5px 8px;
  width: 5rem;
  border-radius: 5px;
}

.colinactivestyles {
  color: #161616;
  font-size: 14px;
  font-family: GraphikRegular;
  background-color: #E0E0E0;
  text-align: center;
  padding: 5px 8px 5px 8px;
  width: 5rem;
  border-radius: 5px;
}

/***********************************Styles for unauthorized popup button*****************************/

/************************Html FONTS**********************/

@font-face {
  font-family: "GraphikBold";
  src: url(assets/Fonts/graphik-fonts/fonts/Graphik-Bold.ttf) format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "GraphikBlack";
  src: url(assets/Fonts/graphik-fonts/fonts/Graphik-Black.ttf) format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "GraphikBlackItalic";
  src: url(assets/Fonts/graphik-fonts/fonts/Graphik-BlackItalic.ttf) format("truetype");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "GraphikMedium";
  src: url(assets/Fonts/graphik-fonts/fonts/Graphik-Medium.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "GraphikLight";
  src: url(assets/Fonts/graphik-fonts/fonts/Graphik-Light.ttf) format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "GraphikMediumItalic";
  src: url(assets/Fonts/graphik-fonts/fonts/Graphik-MediumItalic.ttf) format("truetype");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "GraphikLightItalic";
  src: url(assets/Fonts/graphik-fonts/fonts/Graphik-ExtralightItalic.ttf) format("truetype");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "GraphikExtraLight";
  src: url(assets/Fonts/graphik-fonts/fonts/Graphik-Extralight.ttf) format("truetype");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "GraphikRegularItalic";
  src: url(assets/Fonts/graphik-fonts/fonts/Graphik-RegularItalic.ttf) format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "GraphikSemiBoldItalic";
  src: url(assets/Fonts/graphik-fonts/fonts/Graphik-SemiboldItalic.ttf) format("truetype");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "GraphikSemiBold";
  src: url(assets/Fonts/graphik-fonts/fonts/Graphik-Semibold.ttf) format("truetype");
  // font-weight: 600;
  // font-style: normal;
  // font-display: swap;
}

@font-face {
  font-family: "GraphikRegular";
  src: url(assets/Fonts/graphik-fonts/fonts/Graphik-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "GraphikLightItalic";
  src: url(assets/Fonts/graphik-fonts/fonts/Graphik-LightItalic.ttf) format("truetype");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "GraphikBoldItalic";
  src: url(assets/Fonts/graphik-fonts/fonts/Graphik-BoldItalic.ttf) format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "GraphikThin";
  src: url(assets/Fonts/graphik-fonts/fonts/Graphik-Thin.ttf) format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "GraphikSuper";
  src: url(assets/Fonts/graphik-fonts/fonts/Graphik-Super.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "GraphikSuperItalic";
  src: url(assets/Fonts/graphik-fonts/fonts/Graphik-SuperItalic.ttf) format("truetype");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "GraphikThinItalic";
  src: url(assets/Fonts/graphik-fonts/fonts/Graphik-ThinItalic.ttf) format("truetype");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

/************************Html FONTS**********************/

/************************Html CSS**********************/
html,
body {
  overflow-x: hidden;
  background-color: #ffffff;
  height: 100%;
  margin: 0;
}
#tablea-container iframe{height:calc(100vh - 160px) !important}



.no-space {
  white-space: nowrap;
}

.application-padding-btm {
  padding-bottom: 3rem;
  margin-top: 2.9rem;
}

/********************************* Dialog Styles ************************************/
.userenquiryctn {
  .p-dialog .p-dialog-content {
    padding: none !important;
  }
}

/********************************* Dialog Styles ************************************/

/************************Html CSS***********************/

/************************************** Common css for Static Pages ***************************************/

.static-bg-color {
  background-color: $white-color;
  padding-top: $static-pad-top;
}

.static-heading {
  text-align: $static-text-align;
  font-size: $static-font-size;
  font-family: GraphikMedium;
}

.static-secondary-heading {
  font-size: $static-sec-font-size;
  font-family: GraphikMedium;
}

/************************************** Common css for Static Pages ***************************************/

/************************************** Common css for Scroll Bar ***************************************/

::-webkit-scrollbar {
  width: 0.7em;
  border-radius: $scrollbar-brd-radius;

  // background: linear-gradient(180deg, white 50%, black 50%);
}

::-webkit-scrollbar-thumb {
  background: $table-bg-color;
  // border-radius: $scrollbar-brd-radius;
}

// ::-webkit-scrollbar-track {
//   background:$white-color;
//   // border-radius: $scrollbar-brd-radius;
//   // border-bottom: 50px solid black;
//   margin-block-end: 2.9rem;
//   margin-block-start: 4.6rem;
//   position: absolute !important;
//   // margin-bottom: 50px;
// }

.p-component,
.scroll {
  ::-webkit-scrollbar {
    width: 0.7em;
    border-radius: $scrollbar-brd-radius;
    background: none;
  }

  ::-webkit-scrollbar-thumb {
    background: $table-bg-color;
    border-radius: $scrollbar-brd-radius;
  }

  ::-webkit-scrollbar-track {
    margin-block-end: 0rem;
    margin-block-start: 0rem;
    background: $white-color;
    border-radius: $scrollbar-brd-radius;
  }
}

.scrollstyle.p-dialog-content {
  ::-webkit-scrollbar {
    width: 1em;
  }
}

/************************************** Common css for Scroll Bar ***************************************/

/************************************** Common css for no record found ***************************************/
.norecord {
  font-size: $norecord-font-size;
  font-family: GraphikRegular;
  color: #161616;
}

/************************************** Common css for no record found***************************************/

/************************************** Common css for Nav Bar ***************************************/



/************************************** Common css for Nav Bar ***************************************/

/************************************** Common css for Component Heading ***************************************/

.heading-padding {
  padding-top: $heading-padding-top;
  // padding-bottom: $heading-padding-bottom;
  // padding-left: $heading-padding-left;
}

/************************************** Common css for Component Heading ***************************************/

/************************************** Common css for Discovery Labels***********************************/

.discovery-label {
  color: $label-color;
  font-size: $label-font-size;
  font-family: GraphikRegular;

}

/************************************** Common css for Discovery Label**********************************/

/************************************** Common css for Discovery Search Panel****************************/

.discovery-search-panal {
  background-color: $white-color;
  padding-top: $search-panal-padding-top;
  padding-bottom: $search-panal-padding-bottom;
  // padding-left: $search-panal-padding-bottom;
  // padding-right: $search-panal-padding-bottom;
}

/************************************** Common css for Discovery Search Panel ***************************/

/************************************** Common css for Discovery-dropdown ********************************/
.discovery-dropdown {
  .p-dropdown {
    width: $p-dropdown-width;

    .p-dropdown-label.p-placeholder {
      font-family: GraphikMedium;
    }

    .p-dropdown-trigger {
      background: $discovery-search-btn-color !important;
    }
  }
}

/************************************** Common css for Discovery-dropdown *******************************/

/************************************** Common css for Data-dropdown ********************************/
.data-dropdown {
  .p-dropdown {
    width: $p-dropdown-width;

    .p-dropdown-label.p-placeholder {
      font-family: GraphikMedium;
    }
  }
}

/************************************** Common css for Data-dropdown *******************************/


/************************************** Common css for Discovery-textinput *******************************/
.discovery-textinput {
  width: $p-textbox-width;
}

.p-inputtext {
  font-family: GraphikRegular !important;
  font-size: $global-font-size !important;
}

.enquiry {

  .p-inputtext,
  .p-dropdown {
    background-color: $enquiry-input-bg-color;
  }

  .p-dropdown-items-wrapper {
    max-height: 120px !important;
  }
}

/************************************** Common css for Discovery-textinput *******************************/

/************************************** Common css for Discovery Invite User Button ***********************/

.p-button {
  font-size: $global-font-size;

  .p-button-warning {
    margin: 0;
  }
}

.discovery-invite-usr-btn {
  background-color: $indigo;
  color: $white-color;
  height: $invite-button-height;
}

.discovery-invite-usr-btn:hover {
  background-color: $blurrywood;
  color: $white-color;
}

/************************************** Common css for Discovery Invite User Button **********************/

/************************************** Common css for Discovery Submit Button ***********************/

.discovery-submit-btn {
  background-color: $indigo;
  color: $white-color;
  width: $submit-btn-width;
}

.discovery-submit-btn:hover:enabled {
  background-color: $blurrywood;
  color: $white-color;
}

/************************************** Common css for Discovery Submit Button **********************/

/************************************** Common css for Discovery Cancel Button ***********************/

.discovery-cancel-btn {
  background-color: $red;
  color: $white-color;
  width: $cancel-btn-width;
}

.discovery-cancel-btn:hover:enabled {
  background-color: $blurrywood;
  color: $red;
}

.p-button-label {
  font-family: GraphikMedium;
  font-size: 14px;
  color: #161616;
}

/************************************** Common css for Discovery Cancel Button **********************/

/************************************** Common css for Discovery Search Button ***************************/

.discovery-search-btn {
  .p-button-label {
    font-family: GraphikMedium;
  }

  .p-button-warning {
    width: $discovery-search-btn-width;
    // background-color: $discovery-search-btn-color !important;
    // border: 1px solid $discovery-search-btn-color;
    border-radius: 3rem;
  }

  .p-button.p-button-warning {
    width: $submit-btn-width;
  }

  .p-button-warning:hover:enabled {
    // background-color: $discovery-search-btn-hover-focus-active-color !important;
  }

  .p-button-warning:active {
    background-color: black !important;
  }

  .p-button-warning:focus {
    // background-color:$discovery-search-btn-hover-focus-active-color !important;
    // box-shadow: #008000 !important;
  }

  .p-button:disabled {
    //background-color:$discovery-search-btn-disabled-color !important;
    //border: 1px solid $discovery-search-btn-disabled-color;
  }
}

.dataingestcmpnt {
  .discovery-search-panal {
    .row {
      --bs-gutter-x: 0.5rem !important;
    }
  }

  .reupload {
    .p-dropdown-items {

      max-height: 150px;

    }
  }
}

/************************************** Common css for Discovery Search Button **************************/


/************************************** Common css for Discovery-Table *******************************************/
.discovery-table {
  .p-datatable>.p-datatable-wrapper{
    border-radius: 8px !important;
    border:1px solid #E3E3E3 !important;
  }
  .p-datatable .p-datatable-thead>tr>th {

    background-color: #f4f4f4 !important;

    color: #6F6F6F !important;

    font-family: GraphikMedium;
    font-size: 14px;
    font-weight: 500 !important;
    border-bottom:transparent !important;

    i {
      vertical-align: text-bottom !important;
    }
  }
  .p-datatable .p-datatable-header{
   padding:1rem 1rem 1rem 0rem !important;
    }
    .p-datatable-thead{
      border:#E3E3E3 !important;
      tr{
        border-bottom: 1px solid #E3E3E3 !important;
      }
    }

  .p-datatable .p-datatable-tbody>tr>td {
    font-family: GraphikRegular;
    font-size: 14px !important;
    color: #6F6F6F;
  }
  .p-datatable .p-sortable-column:not(.p-highlight):hover {
    background: #F4F4F4 !important;
    color: #6F6F6F !important;
  }

  .p-datatable .p-datatable-tbody>tr {
    color: #161616;
    border:#e3e3e3 !important;
  }

  .edit-icon:hover {
    cursor: pointer;
    color: $fa-edit-hover;
  }

  .p-datatable.p-datatable-striped .p-datatable-tbody>tr:nth-child(even) {
  background:$strip-bg-color;
  }

  .p-paginator {
    justify-content: end;
  }

  .pi-sort-alt:before {
    content: "\e99e";
    color: #6F6F6F !important;
  }

  .pi-sort-amount-up-alt:before {
    content: "\e9a2";
    color: #6F6F6F !important;
  }

  .pi-sort-amount-down:before {
    content: "\e9a0";
    color: #6F6F6F !important;
  }

  .p-datatable .p-sortable-column.p-highlight:hover {
    background: #F4F4F4 !important;
    color: #6F6F6F !important;
  }

  // .p-datatable .p-sortable-column:hover {
  //   background: $table-bg-color !important;
  //   color: $table-color !important;
  // }
  .p-datatable-auto-layout>.p-datatable-wrapper>table,
  .p-datatable-responsive-scroll>.p-datatable-wrapper>table {
    table-layout: auto !important;
    overflow-y: hidden;
  }

  .p-datatable .p-sortable-column:focus {
    // box-shadow:inset 0 0 0 0.15rem #000000;
    box-shadow: none !important;
  }
}

.discovery-Datatable {
  .p-datatable .p-datatable-tbody>tr>td {
    // padding: 6px;
    padding-left: 14px !important;
  }

  .p-datatable .p-datatable-thead>tr>th {
    white-space: nowrap;
  }
}

.p-datatable .p-paginator-bottom {
  justify-content: end !important;
}

.p-link:focus {
  box-shadow: none !important;
}

.p-paginator {
  justify-content: end !important;

  .p-paginator-pages .p-paginator-page.p-highlight {
    background-color: rgba(33, 37, 41, 0.7) !important;
    color: white !important;
    pointer-events: none;
  }

  .p-paginator-next:not(.p-disabled):not(.p-highlight):hover {
    background-color: #e6e6e6 !important;
  }

  .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover {
    background-color: #e6e6e6 !important;
  }

  .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
    background-color: #e6e6e6 !important;
  }

  .p-paginator-first:not(.p-disabled):not(.p-highlight):hover {
    background-color: #e6e6e6 !important;
  }
}

/************************************** Common css for Discovery-Table *******************************************/

/************************************** Common css for Discovery-Tabview *******************************************/

.p-tabview {

  .p-tabview-panels,
  .p-tabview-nav {
    padding: 5px 0px 0px 0px !important;

    .p-highlight {
      .p-tabview-nav-link {
        border-bottom-color: #009AAB !important;
        color: $black !important;
        box-shadow: none !important;
        font-family: GraphikMedium;

        font-size: $global-font-size;
      }

      .p-tabview-nav-link:focus {
        border-color: #009AAB !important;
        // color: $black !important;
        box-shadow: #009AAB !important;
        // border: 1px #009AAB !important;
        font-family: GraphikMedium;

        font-size: $global-font-size;
      }
    }
  }
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  border-bottom-width: 3px !important;
}

.setting {
  .p-tabview .p-tabview-nav li {
    a {
      background: none !important;
      font-family: GraphikRegular;
    }
  }
}

.CompanyComponent {
  .p-tabview {
    .p-tabview-panels {
      margin-left: 15px !important;
    }
  }
}

/************************************** Common css for Discovery-Tabview *******************************************/

/************************************** Common css for Discovery-FileUpload *******************************************/

.discover-fileupload {
  .p-button-label {
    color: #161616;
    font-size: 14px !important;
    font-family: GraphikMedium;
  }

  .p-button.p-fileupload-choose {
    background-color: #FFEB00;
    border: none !important;
    border-radius: 100px;
    color: #161616 !important;
    box-shadow: none !important;
    width: $submit-btn-width !important;
  }

  .p-button.p-fileupload-choose:hover {
    background-color: #EBD300 !important;
    color: $black !important;
    box-shadow: none !important;
  }

  .p-button.p-fileupload-choose:focus {
    background-color: #EBD300;
    border: 1px solid #007A8A !important;
  }

  .p-button.p-fileupload-choose:active {
    background-color: #B8A200 !important;
    border: none !important;

  }

  .p-fileupload {
    .p-fileupload-content {
      display: none !important;
    }

    .p-fileupload-buttonbar {
      background: $upload-buttonbar-color !important;
      border: none !important;
      border-top-right-radius: none !important;
      border-top-left-radius: none !important;
      background: none !important;
      padding: 0rem !important;
      box-shadow: none !important;
    }
  }
}

/************************************** Common css for Discovery-FileUpload *******************************************/

/***************************************common style for p-card and toggle*******************************************************/

.help {
  .p-input-icon-right>i:last-of-type {
    right: $card-icon-right !important;
  }
}



.form-switch .form-check-input {
  height: $toggle-height !important;
  width: 3rem !important;
}

.pi-spinner:before {
  font-size: $spinner-font-size !important;
}

/********************************************common style for p-card and toggle**********************************************/

/************************************** Data Component Styles *******************************************/
.data-component {
  .progressbar {
    img {
      height: 50px !important;
      width: 50px !important;
    }

    .profile-component {
      font-family: GraphikRegular;
      font-size: $global-font-size;
    }

    li {
      list-style-type: none;
      float: left;
      width: $progress-bar-li-width;
      position: relative;
      text-align: center;
      font-size: $global-font-size;
      font-family: GraphikRegular;
    }

    li::before {
      line-height: $progress-bar-li-height;
      display: block;
      text-align: center;
      margin: 0 auto 10px auto;
      background-color: $progress-bar-li-bg-color;
    }

    li::after {
      content: $progress-bar-li-after-content;
      position: absolute;
      width: $progress-bar-li-after-width;
      height: $progress-bar-li-after-height;
      background-color: $progress-bar-li-after-bg-color;
      top: $progress-bar-li-after-top;
      left: $progress-bar-li-after-left;
      z-index: $progress-bar-li-after-zindex;
    }

    li:first-child::after {
      content: none;
    }

    li.active:after {
      background-color: $red;
    }

    .red-text {
      color: $red;
    }
  }

  .content {
    background-color: $content-bg-color;
    padding: $content-padding;

    .step {
      img {
        width: $content-step-img-width;
        height: $content-step-img-height;
      }

      .gray-box {
        background-color: $step-gray-box-bg-color;
        color: $step-gray-box-color;
        font-size: $step-gray-box-font-size;
        font-family: GothamBold;
        padding: $step-gray-box-padding;
        margin-top: $step-gray-box-margin-top;
        width: $step-gray-box-width;

        .file-type {
          color: $content-step-file-type-color;
        }
      }

      .step-title {
        font-size: $step-title-font-size;
        font-family: GraphikMedium;
        font-weight: $step-title-font-weight;
        color: $step-title-color;
      }

      .step-info {
        font-size: $step-info-font-size;
        font-family: GraphikRegular;
        color: $step-info-font-color;
      }
    }
  }
}

/************************************** Data Component Styles *******************************************/

/************************************** Enquiry Questionary Styles ***************************************/

.progress {
  height: 100%;
  background-color: $white-color;
}

.progress li {
  list-style-type: none;
  float: left;
  width: 33.33%;
  position: relative;
  text-align: center;
}

.progress li::before {
  content: "";
  width: 24px;
  height: 24px;
  border: 1px solid black;
  display: block;
  text-align: center;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  line-height: 30px;
  background-color: white;
}

.progress li::after {
  content: "";
  position: absolute;
  width: 100%;
  // height: 2px;
  border-top: 2px dashed #236092;
  margin-left: 12px;
  // background-color: black;
  top: 12px;
}

.progress li:last-child::after {
  content: none;
}

.progress li.active {
  color: #236092;
}

.progress li.active::before {
  border-color: #236092;
  background-color: #236092;
}

.progress li.active::after {
  border-top: 2px solid #236092;
}

.customecol {
  .p-inputtext {
    width: 55px !important;
  }
}

/************************************** Enquiry Questionary Styles ***************************************/

/**************************Styles for Home Component************************/
.home {
  .pi-chevron-right:before {
    color: $home-color;
    zoom: $home-zoom;
  }

  .pi-chevron-left:before {
    color: $home-color;
    zoom: $home-zoom;
  }
}

.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
  background-color: $home-button !important;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator button {
  background-color: $p-caurosal-header-color !important;
}

.p-confirm-dialog .p-dialog-content {
  padding-top: 20px !important;
}

/***************************Styles for Home Component**************************/

/******************************sytles for setting*******************************/
.setting {
  .p-tabview .p-tabview-nav {
    background: none;
    border: none;
    border-width: none;
  }

  .p-tabview .p-tabview-panels {
    background: none;
    padding: none;
    border: none;
    color: none;
    border-bottom-right-radius: none;
    border-bottom-left-radius: none;
  }

  .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    border: solid #dee2e6;
    border-width: 0 0 2px 0;
    border-color: transparent transparent #dee2e6 transparent;

    color: #161616;
    padding: none !important;
    font-weight: 600 !important;
    margin: 0 0 -2px 0;
  }

  .p-tabview .p-tabview-panels .p-highlight .p-tabview-nav-link,
  .p-tabview .p-tabview-nav .p-highlight .p-tabview-nav-link {
    border-color: $discovery-search-btn-color !important;
    color: #182853 !important;
    box-shadow: none !important;
    font-family: GraphikRegular;
    font-size: 14px;
    font-weight: 600 !important;
  }

  .p-checkbox .p-checkbox-box.p-highlight {
    border-color: darkblue;
    background: darkblue;
  }

  .p-listbox .p-listbox-list {
    padding: none !important;
  }

  .p-listbox .p-listbox-list {
    padding: none !important;
  }

  .p-listbox .p-listbox-list .p-listbox-item {
    padding: 0.2rem 1rem !important;
  }
}

p-checkbox {
  vertical-align: text-bottom !important;
}

/***********************************setting styles***********************************/

.p-card {
  height: 100% !important;
}

/******************************styles for Upload files component**********************/

.upload {
  .p-button {
    display: inline !important;
  }

  .p-fileupload-row>div {
    flex: 1 1 auto !important;
    width: auto !important;
  }

  .p-button.p-fileupload-choose {
    background-color: #FFEB00;
    display: flex !important;
    width: fit-content;
    align-items: center;
    border-radius: 3rem;
    border: none !important;
    color: $black !important;
  }

  .p-button.p-fileupload-choose:hover {
    background-color: #EBD300 !important;
    color: $black !important;
  }

  .upload .p-button.p-fileupload-choose {
    box-shadow: 0 0 0 0.1rem #EBD300 !important;
  }

  .p-fileupload {
    padding-left: 0% !important;
    .p-fileupload-content {
      border: $upload-content-border !important;
      width: $upload-content-width !important;
      height: $upload-content-height !important;
      margin-top: $upload-content-MarTop !important;
      margin-bottom: $upload-content-MarBtm !important;
      background-color: $upload-content-bg;
    }
  
    .p-fileupload-buttonbar {
      border: none !important;
      padding-bottom: $buttonbar-padbtm !important;
      padding-left: $buttonbar-padleft !important;
      padding-top: $buttonbar-padtop !important;
      padding-right: $buttonbar-padright !important;
      background-color: $buttonbar-bg;
    }
  }

  .p-overlaypanel {
    width: 100px !important;
  }

  ol {
    padding-left: 1rem !important;
  }

  .p-message .p-message-wrapper {
    padding: 0.5rem !important;
  }


}

// 
/******************************styles for Advance control to UploadMultipleFile component**********************/

.advance-upload
{
    .p-fileupload.p-fileupload-advanced.p-component{
    border: $upload-content-border !important;
  }
  .p-fileupload-row > :first-child {
    display: none !important;
  }
  .p-fileupload-row>div {
    flex: 1 1 auto;
    // width: auto !important;
    /* width: 25%; */
}
.p-fileupload-filename {
  word-break: break-all;
  width: 50% !important;
}
.p-fileupload .p-fileupload-content {
  
  font-size: 15px;
  font-weight: 100;
  max-height: 300px;
  overflow: scroll !important;
}
}

/*********************************styles for Upload files component**********************/

/*****************************************common css for replacement of br***************/
.margin {
  margin-top: 10px;
}

.box-margin {
  margin-top: 220px;
}

.margin-left-5 {
  margin-left: 5px !important;
}

.margin-bottom-19 {
  margin-bottom: 19px !important;
}

.margin-left-50 {
  margin-left: 50px !important;
}

.margin-top-10 {
  margin-top: 10px !important;
}

.margin-top-25 {
  margin-top: 25px !important;
}

.margin-left-20 {
  margin-left: 20px !important;
}

.padding-top-5 {
  padding-top: 5px !important;
}

.padding-bottom-5 {
  padding-bottom: 5px !important;
}

.padding-bottom-10 {
  padding-bottom: 10px !important;
}

.textalign-left {
  text-align: left !important;
}

.textalign-right {
  text-align: right !important;
}

.textalign-center {
  text-align: center !important;
}

.help-margin {
  margin-bottom: 20px;
}

.pos-absolute {
  position: absolute !important;
}

.myclass {
  .p-card-content {
    height: 100px !important;
  }
}

.myclass2 {
  .p-card-content {
    height: 200px !important;
  }
}

/*****************************************common css for replacement of br***************/

/*****************************************common css for inside dropdown items***************/

// .p-component {
//   font-size: $global-font-size-no !important;
//   font-family: GraphikRegular !important;
// }
.p-component {
  font-size: 14px !important;
  font-family: GraphikRegular !important;

  @media screen and (min-width:576px) and (max-width: 2400px) {
    font-size: getVwValue($global-font-size-no) * 1vw !important;
  }
}

/*****************************************common css for inside dropdown items***************/

/*****************************************common css for form check label***************/
.form-check-label {
  font-size: 14px !important;
  font-family: GraphikRegular !important;
}

/*****************************************common css for form check label***************/
.customalign {
  padding-top: 1rem;
}

.customlabel {
  font-size: 15px;
}

.customimage {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.p-blockui-document.p-blockui.p-component-overlay {
  background-color: rgba(0, 0, 0, 0.7) !important;
  transition-property: background-color;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
}

.p-progressbar-determinate .p-progressbar-value {
  background: $Progressbar-color !important;
}

/********************************************common css for mandantory field**************/
.mandatory::after {
  content: $mandatory-content;
  font-size: $mandatory-font-size;
  color: $mandatory-color;
}

/********************************************common css for mandantory field**************/

.user-entry,
.UsersDashboard {
  .p-listbox {
    border: none !important;
  }
}

.user-entry
.p-dialog .p-dialog-content{
  padding: 0 1.5rem 0 !important;
}

.user-entry{
  .p-dialog .p-dialog-header{
    padding-left: 2.1rem !important;
  }
}

/************************************common css for industry dropdown of enquiry page**************/

// .p-dropdown-items-wrapper{
//   max-height: 120px !important;
// }
/***********************************common css for industry dropdown of enquiry page**************/

/**********************************common css for select account of user entry**************/
.aligned {
  margin-top: -10px !important;
}

/**********************************common css for select account of user entry**************/


/*********************************sytles for tooltip**************************************/


.float {
  .p-speeddial {
    position: fixed;
    right: $speeddail-right !important;
    bottom: $speeddail-bottom !important;
    //bottom: 2rem !important;
  }

  .p-speeddial-button {
    height: 48px !important;
    width: 48px !important;
    font-weight: 900 !important;
    background-color: $discovery-search-btn-color;
    //box-shadow: none;
    border-color: $discovery-search-btn-color;
    color: $speeddail-button-color !important;
  }

  .p-button:enabled:hover {
    background-color: $discovery-search-btn-color !important;
    border-color: $discovery-search-btn-color !important;
    color: $speeddail-button-color !important;
  }
}

.p-overlaypanel {
  width: $Overlay-width !important;
  height: $Overlay-height !important;
}

/*********************************sytles for tooltip**************************************/


/*********************************sytles for picklist**************************************/


.picklist {
  .p-button {
    color: $picklist-button-color;
    background: #FFEB00 !important;
    border: 1px solid #FFEB00 !important;
    border-radius: 100px;
  }

  .p-button:hover {
    background-color: #EBD300 !important;
    color: $picklist-button-color !important;
  }

  .p-button:focus {
    background-color: #EBD300 !important;
    border: 1px solid #007A8A;
  }

  .p-button:active {
    background-color: #B8A200;
  }

  .p-picklist .p-picklist-header .p-picklist-title {
    font-size: 16px;
    font-family: 'GraphikMedium';
    color: #161616;
    font-weight: normal !important;
  }

  .p-picklist .p-picklist-header,
  .p-picklist .p-picklist-filter-container {
    padding: $picklist-filter-container-padding !important;
  }

  .p-picklist .p-picklist-list {
    padding: 0.3rem 0rem !important;
  }

  .p-picklist-empty-message {
    font-size: 14px !important;
    color: #161616 !important;
  }

  .p-picklist-filter-input {
    font-size: 14px !important;
    color: #161616 !important;
    border: 1px solid #C6C6C6 !important;
  }

  .p-inputtext:enabled:focus {
    border: 2px solid #007A8A !important;
    box-shadow: 0 0 0 0 transparent !important;
  }

  .p-inputtext:hover {
    border: 1px solid #6F6F6F !important;
  }

  .p-inputtext::placeholder {
    color: #6F6F6F !important;
    font-size: 14px !important;
  }
}

/*********************************sytles for picklist**************************************/

/*********************************sytles for Dashboard**************************************/


.stickyHeader {
  .p-autocomplete {
    width: 100% !important;
    font-size: 14px !important;
    // height: 28px !important;
  }

  .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-empty-message {
    color: #161616 !important;
    font-size: 14px !important;
    font-family: GraphikRegular !important;
  }

  .p-float-label .p-inputwrapper-focus~label,
  .p-float-label .p-inputwrapper-filled~label {
    background-color: #dadada !important;
  }

  .p-autocomplete-input.p-inputtext.p-component.p-autocomplete-dd-input:focus {
    border: 2px solid #007A8A;
    box-shadow: 0 0 0 0 transparent !important;
  }

  .p-autocomplete-input.p-inputtext.p-component.p-autocomplete-dd-input:focus~.p-element.p-autocomplete-dropdown.p-button.p-component.p-button-icon-only {
    border: 2px solid #007A8A;
    box-shadow: 0 0 0 0 transparent !important;
  }


  .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item-group {
    //background-color: $discovery-search-btn-color !important;
    padding: 0.5rem 1rem !important;
  }

  .noRecords {
    .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item-group {
      background-color: $white-color !important;
      font-weight: 200 !important;
    }
  }

  span.p-dropdown-label.p-inputtext.p-placeholder {
    // padding: 3px !important;
    // margin-left: 3px;
  }

  .p-autocomplete-dd .p-autocomplete-input {
    // height:  28px !important
  }

  .p-treeselect .p-treeselect-label {
    // padding: 2px !important;
  }

  button.p-autocomplete-dropdown.p-component.p-button-icon-only {
    // padding: 1px !important;
    background-color: #FFFFFF !important;
  }

  button.p-button-warning.no-space.p-button.p-component {
    // padding: 3px;
  }

  //   span#pr_id_4_label {
  //     padding: 3px;
  // }

  span.p-element.p-dropdown-label.p-inputtext {
    // padding: 3px !important;
  }

  button.p-element.round.p-button-rounded.p-button-warning.p-button.p-component.p-button-icon-only {
    // height: 28px !important;
  }

  button.p-element.p-button-warning.no-space.p-button.p-component:hover:enabled {
    background-color: #ffe4c4 !important;
  }

  .p-multiselect.p-component {
    //  height:  28px !important;
  }

  .p-multiselect-label.p-placeholder,
  .p-multiselect-label {
    // padding: 3px !important;
    // margin-left: 3px;
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    margin: 0;
    padding: 0.3rem 2rem !important;
    border: 0 none;
    color: #495057;
    background: transparent;
    transition: box-shadow 0.2s;
    border-radius: 0;
  }

  .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
    margin: 0;
    padding: 0.3rem 2rem !important;
    border: 0 none;
    color: #495057;
    background: transparent;
    transition: box-shadow 0.2s;
    border-radius: 0;
  }

  .p-dropdown-item {
    color: #161616 !important;
  }
}

.dashboard {

  .p-button.p-button-icon-only.p-button-rounded {
    position: relative;
    margin-left: $button-rounded-margin-left;
    margin-top: $button-rounded-margin-top;
    height: $button-rounded-height !important;
  }

  .p-button.p-button-icon-only {
    width: $button-icon-width;
    position: relative;
    padding: 0.5rem 0;
    color: $splitter-gutter-bg;
    box-shadow: none !important;
  }

  .p-tree-container {

    .SelectedParentNode {
      .p-tree-toggler-icon {
        color: $discovery-search-btn-color !important;
      }

    }

    .SelectedParentNode>div:first-child {
      .p-treenode-label {
        font-weight: bold !important;
      }
    }

    .SelectedNode {
      background-color: $discovery-search-btn-color !important;
      border-radius: 5px !important;
      font-weight: bold !important;
    }
  }

  .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
    background: none !important;
  }



  // button.p-element.p-ripple.p-autocomplete-dropdown.p-button.p-component.p-button-icon-only{
  //   width:  2.357rem !important
  // }

  button.p-autocomplete-dropdown.p-button.p-component.p-button-icon-only {
    // width:  2.357rem !important
  }

  .p-sidebar-left {
    top: 58px !important;
  }

  .col.discovery-search-btn.margin-top-5 {
    width: 0 !important;
  }

  .p-tree.p-component.p-tree-selectable.p-tree-flex-scrollable {
    height: 70%;
    padding: 5px;
  }


  .p-tree.p-component.p-tree-selectable.p-tree-flex-scrollable {
    max-width: 390px;
  }

  .p-treenode-children button.p-ripple.p-element.p-tree-toggler.p-link {
    width: 0px !important;
    padding-left: 30px;
  }
}

.p-splitter-gutter {
  width: $splitter-gutter-width !important;
  background: $splitter-gutter-bg !important;
}

.p-tree {
  border: 0px none !important;
}

.p-splitter-horizontal>.p-splitter-gutter>.p-splitter-gutter-handle {
  background-color: $splitter-gutter-bg !important;
}

.word-break {
  word-break: break-all !important;
}

.p-treenode-label {
  white-space: pre-wrap;
  width: max-content;
  // word-break: break-all !important;
}

////**styles for Dashboard****/////////////
///////*****styles for label Caps****//////
.ButtonLabel {
  text-transform: uppercase;
}



.p-datatable .p-sortable-column {
  white-space: nowrap !important;
}



/*********************************sytles for Label Caps**************************************/

/*****************************styles for confirmpopup**************************/

.scrollstyle {
  height: auto !important;
  overflow: hidden !important;

  .p-datatable-wrapper {
    // max-height: 75vh !important;
    max-height: calc(67vh - 0px) !important;
    margin-top: 10px;
    overflow-y: scroll !important;
  }
}

/*.scrollstyleflex
  height: auto !important;

  .p-datatable-wrapper {
    height: auto !important;
  }
}*/


.tablestyle {
  tr {
    td {
      font-size: 14px !important;
      color: #161616 !important;
    }
  }

  .p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(.p-datatable-tbody) .p-datatable-tbody>tr>td {
    border-width: 1px !important;
  }

  .p-datatable .p-datatable-tbody>tr>td:nth-child(1) {

    font-size: 14px !important;
    color: #161616 !important;
    font-family: GraphikMedium;
    //background-color: red !important;
  }

}

.greyout {
  opacity: 0.5;
}

.p-dialog .p-dialog-content {
  padding: 0 1.5rem 1rem !important;
}

.custompaginator {
  .p-paginator {
    justify-content: left !important;
    padding: 0rem !important
  }
}

.p-paginator-current {
  cursor: context-menu !important;
}


/************************************** Common css for treeselect *******************************/
.discovery-treeselect {
  .p-treeselect {
    width: $p-dropdown-width;

    .p-treeselect-label p-placeholder {
      font-family: GraphikMedium;
    }

    .p-treeselect-trigger {
      background: $discovery-search-btn-color !important;
    }
  }
}

.p-component-overlay.p-sidebar-mask.p-component-overlay-enter.p-component-overlay-leave {
  width: 0 !important;
}

.p-sidebar .p-sidebar-header+.p-sidebar-content {
  padding-top: 2px;
  display: inline-block;
  top: -61px;
}

.CustomInputSize {
  width: 150px !important;
  height: 25px !important;
}

/************************************** Negative Margin Styles for Checkboxes *******************************/

.QuestionaryCheckboxGrid {
  margin-top: -50px;
}

/************************************** Negative Margin Styles for Checkboxes *******************************/

/************************************** Toast Styles *******************************/
.p-toast .p-toast-message {

  .p-toast-message-content {

    padding: 0.5rem !important;



    .p-toast-detail {

      white-space: pre-wrap !important;

      word-break: break-word !important;

      margin: 0.3rem 0 0 0 !important;



    }



    .p-toast-message-icon {

      font-size: 1.5rem !important;

      align-self: center !important;

    }

  }

  .p-toast-icon-close {

    border-left: 1px solid #161616;

    border-radius: 0px !important;

    align-self: center !important;

    padding: 16px !important;

  }

}



.p-toast-bottom-right {

  top: 60px !important;

  left: 50% !important;

  bottom: revert !important;

  right: revert !important;

  transform: translateX(-50%);

}



.p-toast-message-text {

  font-size: 14px !important;

}



//success toast

.p-toast-message-beon-success {

  background: #CAFFC9 !important;

  border: solid #439446;

  border-width: 0 0 0 0 !important;

  color: #161616;

}

.toast-success-icon:before {

  content: url(../src/assets/Images/toast-success-icon.svg) !important;

}



//warning toast

.p-toast-message-beon-warning {

  background: #FFF8D6 !important;

  border: solid #FFF8D6;

  border-width: 0 0 0 0 !important;

  color: #161616;

}

.toast-warning-icon:before {

  content: url(../src/assets/Images/toast-warning-icon.svg) !important;

}

//error toast
.p-toast-message-beon-error {
  background: #FFDEE1;
  border: solid #e60017;
  border-width: 0 0 0 0 !important;
  color: #161616;
}

.toast-error-icon:before {
  content: url(../src/assets/Images/toast-error-icon.svg) !important;
}

/************************************** Toast Styles *******************************/
/*************************************Beon dialogbox*******************************/

.p-dialog .p-dialog-header {
  border-bottom: 0 none;
  background: #ffffff;
  color: #161616 !important;
  height: 24px !important;
  //padding: 1.5rem !important;
  padding-top: 30px !important;
}

.p-dialog .p-dialog-header .p-dialog-title {
  font-weight: 500 !important;
  font-family: GraphikMedium;
  font-size: 20px;
}

.CompanyInfo.p-dialog .p-dialog-header {
  padding: 2rem !important;
}

/*************************************Beon datepicker*******************************/
.beon-datepicker {

  .p-datepicker {
    border-radius: 10px !important;

    table td {
      padding: 0.1rem !important;
      font-size: 14px !important;
      font-family: GraphikRegular;
    }

    table th {
      padding-left: 14px !important;
      font-size: 12px !important;
      font-family: GraphikRegular;
    }

    table td {
      .p-disabled {
        color: #A8A8A8 !important;
      }
    }

    table th>span {
      color: #525252 !important;
      font-weight: 400 !important;
    }

    table td>span {
      color: #161616 !important;
    }

    table td>span.p-highlight {
      background: #007A8A !important;
      color: #FFFFFF !important;
    }

    table td>span:focus {
      box-shadow: none !important;
    }

    table td.p-datepicker-today>span {
      color: #161616 !important;
    }

    .p-datepicker-header .p-datepicker-title .p-datepicker-year {
      color: #161616 !important;
      font-family: GraphikMedium;
      font-weight: 600 !important;
      font-size: 16px !important;
    }

    .p-datepicker-header .p-datepicker-title .p-datepicker-month {
      color: #161616 !important;
      font-family: GraphikMedium;
      font-weight: 600 !important;
      font-size: 16px !important;
    }
  }

  .p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
    background: #FFFFFF !important;
    border: 1px solid #C6C6C6 !important;
    color: #161616;
  }

  .p-float-label .p-inputwrapper-focus~label,
  .p-float-label .p-inputwrapper-filled~label {
    top: 0 !important;
    font-size: 12px !important;
    font-family: GraphikRegular;
    background: white !important;
    color: #007A8A;
    padding-left: 3px;
    padding-right: 3px;
  }

  .p-calendar {
    .p-inputtext {
      border-right: none !important;
    }

    .p-inputtext:hover {
      border-color: #676767 !important;

    }

    .p-inputtext:hover~.p-button {
      border: 1px solid #6F6F6F !important;
      border-left: none !important;
    }

    .p-inputtext:focus {
      border: 2px solid #007A8A !important;
      box-shadow: 0 0 0 0 transparent !important;
      border-right: none !important;
    }

    .p-inputtext:focus~.p-button {
      border: 2px solid #007A8A !important;
      box-shadow: 0 0 0 0 transparent !important;
      border-left: none !important;
    }

    .p-inputtext:disabled {
      border: 1px solid #E0E0E0 !important;
      background-color: #F4F4F4;
    }

    .p-button {
      background: white !important;
      border: 1px solid #ced4da;
      border-left: none !important;
    }

    .p-button:hover {
      border-color: #CED4DA !important;

    }

    .p-button:focus {
      box-shadow: none !important;
    }
  }

  .pi-calendar:before {
    color: #6F6F6F !important;
  }

}

.beon-datepicker.checkboxerror {
  .p-inputtext {
    border: 2px solid #AB000E;
    box-shadow: 0 0 0 0 transparent !important;
  }

  .p-inputtext~.p-button {
    border: 2px solid #AB000E;
    box-shadow: 0 0 0 0 transparent !important;
  }

  .p-inputtext:hover {
    border: 2px solid #AB000E !important;
    border-right: none !important;
  }

  .p-inputtext:hover~.p-button {
    border: 2px solid #AB000E !important;
    border-left: none !important;
  }

  .p-inputtext:focus {
    border: 2px solid #AB000E !important;
    border-right: none !important;
  }

  .p-inputtext:focus~.p-button {
    border: 2px solid #AB000E !important;
    border-left: none !important;
  }

  .p-button:hover {
    border-color: #AB000E !important;

  }

  .p-float-label .p-inputwrapper-focus~label,
  .p-float-label .p-inputwrapper-filled~label {
    color: #AB000E !important;
  }

  p-calendar.ng-dirty.ng-invalid>.p-calendar>.p-inputtext {
    border-color: #AB000E !important;

  }

  .p-float-label>.ng-invalid.ng-dirty+label {
    color: #AB000E !important;
  }


}

/*************************************Beon datepicker*******************************/

/*************************************Beon prime treeview*******************************/

.beon-treeview {
  .p-treeselect .p-treeselect-label.p-placeholder {
    height: 37px !important;
  }

  .p-treeselect .p-treeselect-label {
    font-size: 14px !important;
    font-family: GraphikRegular;
    color: #161616 !important;
  }

  .p-treenode-label {
    font-size: 14px !important;
  }

  .p-treeselect {
    width: 100%;
  }

  .p-treeselect:hover {
    border: 1px solid #8d8d8d !important;
  }

  .p-treeselect.p-component.p-focus {
    border: 2px solid #007A8A !important;
    box-shadow: 0 0 0 0 transparent !important;
  }

  .p-treeselect:active {
    border: 2px solid #007A8A !important;
  }

  .p-float-label .p-inputwrapper-focus~label {
    top: 0;
    font-size: 12px;
    background: white;
    color: #007A8A !important;
    font-family: GraphikRegular;
  }

  .p-float-label .p-inputwrapper-filled.p-inputwrapper-focus~label {
    top: 0;
    font-size: 12px;
    background: white;
    color: #007A8A !important;
    font-family: GraphikRegular;
  }

  .p-float-label .p-inputwrapper-filled~label {
    top: 0;
    font-size: 12px;
    background: white;
    color: #6f6f6f !important;
    font-family: GraphikRegular;
  }

  .p-tree-empty-message {
    font-size: 14px !important;
    color: #161616 !important;

  }
}


.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
  background: none !important;
  color: #161616 !important;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight:hover {
  background-color: #e9ecef !important;
}

.p-treeselect-filter-container {
  .p-inputtext:hover {
    border: 1px solid #6F6F6F !important;
  }

  .p-inputtext:focus {
    border: 2px solid #007A8A !important;
    box-shadow: 0 0 0 0 transparent !important;
  }

  .p-inputtext:disabled {
    border: 1px solid #E0E0E0 !important;
    background-color: #F4F4F4;
  }
}


/*************************************Beon prime treeview*******************************/

/*************************************Move Hoverglass to left*******************************/

.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container {
  .p-inputtext {
    padding-left: 2rem !important;
    padding-right: unset !important;
    color: #161616 !important;
    font-size: 14px !important;
    font-family: GraphikRegular;
  }

  .p-multiselect-filter-icon {
    right: unset !important;
    left: 0.5rem !important;
  }

  .p-inputtext:hover {
    border: 1px solid #6F6F6F !important;
  }

  .p-inputtext:focus {
    border: 2px solid #007A8A !important;
    box-shadow: 0 0 0 0 transparent !important;
  }

  .p-inputtext:disabled {
    border: 1px solid #E0E0E0 !important;
    background-color: #F4F4F4;
  }
}

.p-dropdown-panel .p-dropdown-header {
  .p-dropdown-filter {
    padding-right: unset !important;
    padding-left: 2rem !important;
    font-size: 14px !important;
    color: #161616 !important;
    font-family: GraphikRegular;
  }

  .p-dropdown-filter-icon {
    right: unset !important;
    left: 0.5rem !important;
  }

  .p-inputtext:hover {
    border: 1px solid #6F6F6F !important;
  }

  .p-inputtext:focus {
    border: 2px solid #007A8A !important;
    box-shadow: 0 0 0 0 transparent !important;
  }

  .p-inputtext:disabled {
    border: 1px solid #E0E0E0 !important;
    background-color: #F4F4F4;
  }
}

.grid-search-input {
  .p-input-icon-right>.p-inputtext {
    padding-right: unset !important;
    padding-left: 2rem !important;
  }

  .p-input-icon-right>i:last-of-type {
    right: unset !important;
    left: 1.5rem !important;
  }
}


.p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container {
  .p-inputtext {
    padding-left: 2rem !important;
    padding-right: unset !important;
    color: #161616 !important;
    font-size: 14px !important;
    font-family: GraphikRegular;
  }

  .p-treeselect-filter-icon {
    right: unset !important;
    left: 0.5rem !important;
  }
}

.p-treeselect-panel .p-treeselect-header {
  .p-treeselect-filter {
    padding-right: unset !important;
    padding-left: 2rem !important;
  }

  .p-treeselect-filter-icon {
    right: unset !important;
    left: 0.5rem !important;
  }
}

.grid-search-input {
  .p-input-icon-right>.p-inputtext {
    padding-right: unset !important;
    padding-left: 2rem !important;
  }

  .p-input-icon-right>i:last-of-type {
    right: unset !important;
    left: 1.3rem !important;
  }
}

.p-picklist .p-picklist-filter-container{
  .p-picklist-filter-input {
    padding-right: unset !important;
    padding-left: 2rem !important;
  }
  .p-picklist-filter-icon {
    right: unset !important;
    left: 0.5rem !important;
  }
}

.p-fileupload .p-fileupload-buttonbar .p-button {
    margin-right: 0.5rem;
    background: #FFEB00 !important;
    color: black;
    border-radius: 47px;
    border: none;
}

/*************************************Move Hoverglass to left*******************************/
// .p-fileupload .p-fileupload-content {
//   background: #0fb800e8 !important;
//   font-size: 20px;
//   font-weight: 600;
// }
// :host ::ng-deep .p-fileupload-content 
//   .p-fileupload-row.ng-star-inserted img {
//  display: none !important;
// }
 

// .p-fileupload-row > :first-child {
//   display: none !important;
// }