.block-ui-wrapper {
  background: rgba(0, 0, 0, 0.1) !important;
}

.cssload-loader {
  position: relative; // left: calc(50% - 31px);
  width: 62px;
  height: 62px;
  border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  perspective: 780px;
}


// .lds-spinner {
//   color: official;
//   display: inline-block;
//   position: relative;
// }
// .lds-spinner div {
//   transform-origin: 40px 34px;
//   animation: lds-spinner 1.2s linear infinite;
// }
// .lds-spinner div:after {
//   content: " ";
//   display: block;
//   position: absolute;
//   top: 3px;
//   left: 37px;
//   width: 6px;
//   height: 18px;
//   border-radius: 20%;
//   background: #000;
// }
// .lds-spinner div:nth-child(1) {
//   transform: rotate(0deg);
//   animation-delay: -1.1s;
// }
// .lds-spinner div:nth-child(2) {
//   transform: rotate(30deg);
//   animation-delay: -1s;
// }
// .lds-spinner div:nth-child(3) {
//   transform: rotate(60deg);
//   animation-delay: -0.9s;
// }
// .lds-spinner div:nth-child(4) {
//   transform: rotate(90deg);
//   animation-delay: -0.8s;
// }
// .lds-spinner div:nth-child(5) {
//   transform: rotate(120deg);
//   animation-delay: -0.7s;
// }
// .lds-spinner div:nth-child(6) {
//   transform: rotate(150deg);
//   animation-delay: -0.6s;
// }
// .lds-spinner div:nth-child(7) {
//   transform: rotate(180deg);
//   animation-delay: -0.5s;
// }
// .lds-spinner div:nth-child(8) {
//   transform: rotate(210deg);
//   animation-delay: -0.4s;
// }
// .lds-spinner div:nth-child(9) {
//   transform: rotate(240deg);
//   animation-delay: -0.3s;
// }
// .lds-spinner div:nth-child(10) {
//   transform: rotate(270deg);
//   animation-delay: -0.2s;
// }
// .lds-spinner div:nth-child(11) {
//   transform: rotate(300deg);
//   animation-delay: -0.1s;
// }
// .lds-spinner div:nth-child(12) {
//   transform: rotate(330deg);
//   animation-delay: 0s;
// }
// @keyframes lds-spinner {
//   0% {
//     opacity: 1;
//   }
//   100% {
//     opacity: 0;
//   }
// }


// .lds-spinner {
//   display: inline-block;
//   position: relative;
//   width: 80px;
//   height: 80px;
// }
// .lds-spinner div {
//   box-sizing: border-box;
//   display: block;
//   position: absolute;
//   width: 64px;
//   height: 64px;
//   margin: 8px;
//   border: 8px solid #fff;
//   border-radius: 50%;
//   animation: lds-spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
//   border-color:  #007a8a transparent transparent;
// }
// .lds-spinner div:nth-child(1) {
//   animation-delay: -0.45s;
// }
// .lds-spinner div:nth-child(2) {
//   animation-delay: -0.3s;
// }
// .lds-spinner div:nth-child(3) {
//   animation-delay: -0.15s;
// }
// @keyframes lds-spinner {
//   0% {
//     transform: rotate(0deg);
//   }
//   100% {
//     transform: rotate(360deg);
//   }
// }


.loaderx {
  position: relative;
  margin: 0 auto;
  width: 80px;
  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }
}

.circularx {
  animation: rotate 2s linear infinite;
  height: 100%;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.pathx {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation: dash 1.2s ease-in-out infinite, color 6s ease-in-out infinite;
  stroke-linecap: round;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

@keyframes color {
  100%,
  0% {
    stroke: #007A8A
    ;
  }
}
